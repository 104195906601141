import { memo, useEffect, useState } from "react";

import { TType } from "../../../assets/translations";

import ProfileSiderMenu from "./profile-sider-menu/profile-sider-menu";

import { getMainNavMenu, getOtherNavMenu } from "./teacher-sidebar-items";
import {
  ChevronHorizontalIcon,
  NavDividerSider,
  NavSiderButtonItem,
  NavSiderImageBlock,
  NavSiderSystemBlock,
  NavSiderSystemImageBlock,
  NavInfoSider,
  NavSider,
  NavSiderInnerContainer,
} from "./profile-sider-styled";

import { getUsersPicture } from "../../../services/user-service";

import { StudentProfile, TeacherProfile } from "../../../types";
import { NavLink, useNavigate } from "react-router-dom";
import { HelpCircle } from "../../../assets/images/svg-elements/help-circle";
import { LogoutIcon } from "../../../assets/images/svg-elements/logout";
import { logoutClickKey, saveToLs } from "../../../services/storage-service";
import { NavSiderTooltip } from "./profile-sider-menu/profile-sider-menu-styled";
import { useGetUser } from "../../../hooks/useGetUser";
import { useVisibleWithRect } from "../../../hooks/useVisibleWithRect";
import { createPortal } from "react-dom";

interface ProfileSiderProps {
  user: TeacherProfile | StudentProfile;
  t: TType;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileSider = memo(
  ({ user, t, isOpen, setIsOpen }: ProfileSiderProps) => {
    const [activeKey, setActiveKey] = useState<string>();

    const { authority } = useGetUser();

    const mainNavMenu = getMainNavMenu(t, authority);
    const otherNavMenu = getOtherNavMenu(t);

    const navigate = useNavigate();

    const { avatarId, firstName, lastName } = user;

    const handleCollapse = () => {
      setIsOpen(!isOpen);
      setActiveKey("");
    };

    const logOut = async () => {
      saveToLs(logoutClickKey, true);
      navigate("/logout");
    };

    useEffect(() => {
      const handleOutsideClick = (e: MouseEvent) => {
        if (!isOpen && !(e.target as HTMLElement).closest(".sider")) {
          setActiveKey(undefined);
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [isOpen]);


    const collapsedCondition = !isOpen ? "true" : undefined;

    const { hideElement: hideSupportElement, isVisible: isSupportVisible, rect: supportRect, ref: supportRef, showElement: showSupportElement } =
      useVisibleWithRect<HTMLAnchorElement>();

    const { hideElement: hideLogOutElement, isVisible: isLogOutVisible, rect: logOutRect, ref: logOutRef, showElement: showLogOutElement } =
      useVisibleWithRect<HTMLButtonElement>();

    const container = document.querySelector(".sider_modal");
    
    return (
      <NavSider collapsed={collapsedCondition} className="sider">
        <NavSiderInnerContainer>
          <div className="sider_modal"></div>
          <NavInfoSider collapsed={collapsedCondition}>
            <img src={getUsersPicture(avatarId)} alt="user image" />
            <p>
              {firstName} {lastName}
            </p>
          </NavInfoSider>
          <NavDividerSider collapsed={collapsedCondition}>
            <div className="line" />
            <button onClick={handleCollapse}>
              <ChevronHorizontalIcon collapsed={collapsedCondition} />
            </button>
          </NavDividerSider>
          <ProfileSiderMenu
            title={t("sidebar.main-menu")}
            activeKey={activeKey}
            isCollapsed={collapsedCondition}
            setActiveKey={setActiveKey}
            items={mainNavMenu}
          />
          <NavDividerSider collapsed={collapsedCondition}>
            <div className="line" />
          </NavDividerSider>
          <ProfileSiderMenu
            title={t("sidebar.other-menu")}
            activeKey={activeKey}
            isCollapsed={collapsedCondition}
            setActiveKey={setActiveKey}
            items={otherNavMenu}
          />
        </NavSiderInnerContainer>
        <NavSiderSystemBlock collapsed={collapsedCondition}>
          <ul>
            <NavLink
              to={"/support"}
              className="link-container"
              onMouseEnter={showSupportElement}
              onMouseLeave={hideSupportElement}
              ref={supportRef}
            >
              <NavSiderSystemImageBlock collapsed={collapsedCondition}>
                <HelpCircle />
              </NavSiderSystemImageBlock>
              <p>{t("support.title")}</p>
              {isOpen && container && createPortal(
                <NavSiderTooltip
                  className="tooltiptext"
                  rect={supportRect}
                  isVisible={isSupportVisible}
                  style={{
                    top: supportRect ? supportRect?.top - supportRect.height - 10 : 0,
                    left: supportRect ? supportRect.left + supportRect.width + 13 : 0,
                  }}
                >
                  {t("support.title")}
                </NavSiderTooltip>, container as Element
              )}
            </NavLink>
            <NavSiderButtonItem collapsed={collapsedCondition} onClick={logOut} ref={logOutRef} onMouseEnter={showLogOutElement} onMouseLeave={hideLogOutElement}>
              <div className="button-container">
                <div className="info-block">
                  <NavSiderImageBlock collapsed={collapsedCondition}>
                    <LogoutIcon />
                  </NavSiderImageBlock>
                  <p className="logout">{t("login.form.button.signout")}</p>
                  {isOpen && container && createPortal(
                <NavSiderTooltip
                className="tooltiptext"
                rect={logOutRect}
                isVisible={isLogOutVisible}
                style={{
                  top: logOutRect ? logOutRect?.top - logOutRect.height - 10 : 0,
                  left: logOutRect ? logOutRect.left + logOutRect.width + 13 : 0,
                }}
              >
                {t("login.form.button.signout")}
              </NavSiderTooltip>, container as Element
            )}
                </div>
              </div>
            </NavSiderButtonItem>
          </ul>
        </NavSiderSystemBlock>
      </NavSider>
    );
  }
);

export default ProfileSider;
