import styled from 'styled-components';

import {device} from "../../constants";


export const PageWrapper = styled.div`
  background: #FFFFFF;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 40px;
    margin-top: var(--header-offset);
  min-height: calc(100vh - var(--header-offset));
  

  .text {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    justify-content: center;
    align-items: center;

    h2, p {
      margin: 0;
      color: #000000;
      text-align: center;
    }

    h2 {
      font-weight: 500;
      font-size: 38px;
      line-height: 46px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media (${device.mobileMax}) {
    row-gap: 32px;

    .text {
      row-gap: 16px;

      h2 {
        font-size: 24px;
        line-height: 32px;
      }

      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
`