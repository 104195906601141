import styled from "styled-components";
import { LessonRequestCardUserInfo } from "../../../lesson-request-card/lesson-request-card-styled";

export const StudentDropdownCardWrapper = styled(LessonRequestCardUserInfo)`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 2fr 1fr 2fr;


  .personal_info {
    flex-direction: row;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
