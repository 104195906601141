import {FC, CSSProperties} from "react";

import {StyledWrapper} from "./not-found.styled";
import {NoResultIcon} from "../../../assets/images/svg-elements/not-result-icon";


interface Props {
    text: string;
    hint?: string;
    style?: CSSProperties;
}

export const NotFoundComponent: FC<Props> = ({text, hint, style}) => {

    return <StyledWrapper style={style}>
        <NoResultIcon/>
        <div className="not_found_details">
            <h2>{text}</h2>
            <p>{hint}</p>
        </div>
    </StyledWrapper>
};