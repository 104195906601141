import React, { FC, useMemo } from "react";

import { TType } from "../../../../assets/translations";

import { CustomLoader } from "../../../elements/custom-loader";
import { NotFoundComponent } from "../../../elements/not-found-component";

import LessonRequestCard from "../lesson-request-card/lesson-request-card";

import LessonRequestHeader from "../lesson-request-header/lesson-request-header";

import { TeacherLessonMenuTabs, UnionTabsType } from "../lesson-request";

import { FAQMenu } from "../../../../types/FAQ";
import { GetRequestsLessonStatuses, RequestLesson } from "../../../../types";

export interface UserLessonRequestProps {
  t: TType;
  requests: RequestLesson[];
  isSwitchActive: boolean;
  setIsSwitchActive: (state: boolean) => void;
  isLoading: boolean;
  selectedKey: UnionTabsType;
  handleChangeSelectedKey: (key: UnionTabsType) => void;
}

enum ReqTabs {
  REQUESTS_FROM_STUDENTS = "requests-from-students",
  OPPORTUNITY_SPOT = "opportunity-spot",
}

const SEARCH_MAPPING: Record<keyof TeacherLessonMenuTabs, boolean> = {
  opportunity_spot: false,
  requests_from_students: false,
};

const FILTER_INPUT_MAPPING: Record<
  keyof TeacherLessonMenuTabs,
  [boolean, boolean]
> = {
  requests_from_students: [false, false],
  opportunity_spot: [true, false],
};

const TeacherLessonRequest: FC<UserLessonRequestProps> = ({
  t,
  requests,
  isSwitchActive,
  setIsSwitchActive,
  isLoading,
  selectedKey,
  handleChangeSelectedKey,
}) => {
  const selectedTeacherKey = selectedKey as keyof TeacherLessonMenuTabs;

  const MENU_TITLES: FAQMenu<TeacherLessonMenuTabs>[] = [
    {
      id: 0,
      label: t(`lesson-requests.tabs.${ReqTabs.REQUESTS_FROM_STUDENTS}`),
      key: "requests_from_students",
    },
    {
      id: 1,
      label: t(`lesson-requests.tabs.${ReqTabs.OPPORTUNITY_SPOT}`),
      key: "opportunity_spot",
    },
  ];

  const REQUEST_LIMIT_MAPPING: Record<keyof TeacherLessonMenuTabs, boolean> = {
    requests_from_students: isSwitchActive ? true : false,
    opportunity_spot: false,
  };

  const renderCards = useMemo(() => {
    if (isLoading) {
      return <CustomLoader />;
    }

    if (!requests || requests.length === 0) {
      return (
        <NotFoundComponent
          text={t("lesson-requests.not-found")}
          style={{ height: "60vh" }}
        />
      );
    }

    return (
      <div className="cards">
        {requests.map((request) => (
          <LessonRequestCard key={request.id} card={request} t={t} />
        ))}
      </div>
    );
  }, [isLoading, requests, t]);

  return (
    <>
      <LessonRequestHeader
        isShowActiveOnly
        handleChangeSelectedKey={handleChangeSelectedKey}
        menuTitles={MENU_TITLES}
        selectedKey={selectedTeacherKey}
        t={t}
        requestsLength={
          requests.filter(
            (request) => request.status === GetRequestsLessonStatuses.CREATED
          ).length || 0
        }
        handleRenderFilterInputs={(key) => FILTER_INPUT_MAPPING[key]}
        showRequestLimit={(key) => REQUEST_LIMIT_MAPPING[key]}
        setIsSwitchActive={setIsSwitchActive}
        isSwitchActive={isSwitchActive}
        switchLabel={t("lesson-requests.switch")}
        isShowSearchFn={(key) => SEARCH_MAPPING[key]}
      />
      {renderCards}
    </>
  );
};

export default TeacherLessonRequest;
