import { useState, useEffect, useRef } from "react";

import {
  InputSelectWrapper,
  InputSelectLabel,
  DropdownButton,
  DropdownList,
  DropdownItem,
  DropdownWrapper,
} from "./input-select-styled";
import { TType } from "../../../assets/translations";

import arrow from "../../../assets/images/svg/select-arrow.svg";
import { SearchIcon } from "../../../assets/images/svg-elements/search";

export interface InputSelectProps<T extends { name?: string }> {
  required?: boolean;
  label?: string;
  placeholder?: string;
  items: T[];
  disabled?: boolean;
  value: T;
  defaultValue?: T;
  onChange: (item: T, multiChoice?: boolean) => void;
  t: TType;
  enableFilter?: boolean;
}

const InputSelect = <T extends { name?: string }>({
  t,
  items,
  label,
  disabled,
  required,
  placeholder = t("main-page.search-query.input-placeholder"),
  value,
  defaultValue,
  onChange,
  enableFilter = true,
}: InputSelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<T | null>(
    defaultValue || null
  );
  const [inputValue, setInputValue] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<T[]>(items);
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current && defaultValue) {
      setSelectedItem(defaultValue);
      onChange(defaultValue);
      initialized.current = true;
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value) {
      setSelectedItem(value);
      setInputValue("");
    }
  }, [value]);

  useEffect(() => {
    if (!enableFilter) {
      setFilteredItems(items);
      return;
    }

    if (!inputValue.trim()) {
      setFilteredItems(items);
    } else {
      setFilteredItems(
        items.filter((item) =>
          item.name?.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    }
  }, [inputValue, items, enableFilter]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleChange = (value: string) => {
    if (enableFilter) {
      setInputValue(value);
      setSelectedItem(null);
    }
  };

  const handleSelect = (item: T) => {
    if (!onChange) return;

    setSelectedItem(item);
    onChange(item);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <InputSelectWrapper>
      {label && (
        <InputSelectLabel
          htmlFor={label}
          className={required ? "required" : undefined}
        >
          {label}
        </InputSelectLabel>
      )}

      <DropdownWrapper ref={dropdownRef} isOpen={isOpen}>
        <DropdownButton
          id={label}
          onClick={toggleDropdown}
          disabled={disabled}
          readOnly={!enableFilter}
          isOpen={isOpen}
          type="text"
          className="input-select"
          onChange={(e) => handleChange(e.target.value)}
          isPlaceholder={!selectedItem && !inputValue}
          placeholder={placeholder}
          value={selectedItem?.name || (enableFilter ? inputValue : "")}
        />
        {!isOpen ? (
          <img src={arrow} alt="arrow" className="arrow" />
        ) : (
          <SearchIcon className="search" />
        )}
        {isOpen && (
          <DropdownList isOpen={isOpen}>
            {filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <DropdownItem key={index} onClick={() => handleSelect(item)}>
                  {item.name}
                </DropdownItem>
              ))
            ) : (
              <DropdownItem disabled>Результати не знайдено</DropdownItem>
            )}
          </DropdownList>
        )}
      </DropdownWrapper>
    </InputSelectWrapper>
  );
};

export default InputSelect;
