import styled from "styled-components";

export const ConnectRequestPageStyled = styled.div`
    padding: 24px 4.8% 150px;
    background: #ECEFF2;
    margin-top: var(--header-offset);

    .top-info{
        max-width: 1440px;
        width: 100%;
        display: flex;
        column-gap: 23px;
        margin-bottom: 32px;

        .navigation {
            font-size: 24px;
            height: fit-content;

            &:hover {
                cursor: pointer;
            }

            &:active {
                color: #5D51A4;
            }
        }

        .info {
            >h2 {
                font-size: 24px;
                font-weight: 500;
                line-height: 32px;
                text-align: left;
                color: #002766;
                margin-bottom: 8px;
            }

            >div {
                span {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }

                .step {
                    margin-right: 12px;
                    color: #00000073;
                }

                .description {
                    font-weight: 500;
                    color: #000000;
                }
            }
        }
    }
`