import { errorExceptions } from "../api/errorBoundary";

import { isLocal } from "../utils/checkIsLocal";

export const setupGlobalErrorHandlers = () => {
  window.addEventListener(
    "error",
    (event: Event) => {
      if (event instanceof ErrorEvent && isLocal()) {
        sendErrorToServer({
          message: event.message,
          source: event.filename || "unknown",
          lineno: event.lineno || 0,
          colno: event.colno || 0,
          stack: event.error?.stack || "No stack trace",
        });
      } else if (event.target instanceof HTMLElement && isLocal()) {
        sendErrorToServer({
          message: "Resource loading error",
          reason: event.target.outerHTML,
        });
      }
    },
    true 
  );

  window.addEventListener(
    "unhandledrejection",
    (event: PromiseRejectionEvent) => {
      sendErrorToServer({
        message: "Unhandled promise rejection",
        reason: String(event.reason),
      });
    }
  );
};

interface ErrorDetails {
  message: string;
  source?: string;
  lineno?: number;
  colno?: number;
  stack?: string;
  target?: string;
  reason?: string;
}

const formatErrorMessage = (errorDetails: ErrorDetails): string => {
  const { message, source, lineno, colno, stack, target, reason } = errorDetails;

  const parts = [
    `Error Message: ${message || "Unknown error"}`,
    source ? `Source: ${source}` : null,
    (lineno !== undefined && colno !== undefined) ? `Location: Line ${lineno}, Column ${colno}` : null,
    stack ? `Stack Trace:\n${stack}` : null,
    target ? `Target Element:\n${target}` : null,
    reason ? `Reason: ${reason}` : null,
    `User-Agent: ${navigator.userAgent}`,
    `Current URL: ${window.location.href}`,
  ];

  return parts.filter(Boolean).join("\n\n");
};

const sendErrorToServer = (errorDetails: ErrorDetails): void => {
    const errorMessage = formatErrorMessage(errorDetails);
    errorExceptions(errorMessage).catch((e) => console.error("Failed to send error to server:", e));
}
