import styled from "styled-components";
import { siderOptimizationStyle } from "../../../config";

interface MainLayoutProps {
    isSiderOpen: boolean;
    shouldRenderProfileSider: boolean;
    isAuthenticated?: boolean;
    isDesktop?: boolean;
  }

export const MainLayoutStyled = styled.div<MainLayoutProps>`
  height: 100vh;

  .content-wrapper {
    display: flex;
    height: 100%;

    &:has(.sider) {
      .policy,
      .footer {
        ${siderOptimizationStyle}
      }
    }

    .filters-bar-wrapper {
      padding-left: ${({ isSiderOpen, isAuthenticated, isDesktop }) => { 
        if (!isAuthenticated || !isDesktop) return "0";

        return isSiderOpen
          ? "var(--sider-opened-width)"
          : "var(--sider-collapsed-width)";
      }};

      transition: padding 0.3s ease-out;

    }
  }
`;

export const ContentWrapper = styled.div<MainLayoutProps>`
 min-width: ${({ isSiderOpen, shouldRenderProfileSider }) => {
   if (!shouldRenderProfileSider) {
     return "100%";
   }

   return isSiderOpen
     ? "calc(100% - var(--sider-collapsed-width))"
     : "calc(100% - var(--sider-opened-width))";
 }}};
  
  height: 100%;
  transition: min-width 0.3s ease-out;
  flex: 1;

  .spinner {
    margin-top: var(--header-offset);
  }
`;
