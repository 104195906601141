import {FC, useCallback, useState} from "react";
import {NavLink, useNavigate} from 'react-router-dom';
import {useMediaQuery} from "react-responsive";
import {FacebookFilled, InstagramFilled, TwitterCircleFilled} from "@ant-design/icons";

import {device} from "../../constants";
import {TType} from "../../../assets/translations";
import {SocialMediaButton, StyledSubscribeInput, FooterWrapper} from "./footer-element.styled";
import {WhiteLogoIcon} from "../../../assets/images/svg-elements/logo-white";
import {TopCategory} from "../../../types";
import {Filter} from "../../pages";
import {SET_SEARCH_BUTTON_CLICKED} from "../../redux";
import {useDispatch} from "react-redux";
import {TelegramIcon} from "../../../assets/images/svg-elements/telegram-icon";
import {FeedbackFooter} from "../feedback/feedback-footer";
import {validateEmail} from "../../../services/user-service";
import {subscribeForNews} from "../../../api/user-api/subscribeForNews";
import {TeachingMeErrorNotification, TeachingMeSuccessNotification} from "../notification";
import { useSiderContext } from "../../../context/SiderContext";


interface Props {
  t: TType;
  isTeacher: boolean;
  isStudent: boolean;
  isCompactFooter: boolean;
  topCategories?: TopCategory[],
  categoriesLoading: boolean,
  categoriesError: boolean,
  performSearch: any;
  filterData: Filter;
  addCategoryTag: (label: string, code: number) => void;
  showLogInModal: () => void;
  showChooseRoleModal: () => void;
}

export const TeachingMeFooterComponent: FC<Props> = ({
                                                       t,
                                                       isTeacher,
                                                       isStudent,
                                                       isCompactFooter,
                                                       topCategories,
                                                       performSearch,
                                                       filterData,
                                                       addCategoryTag,
                                                       showLogInModal,
                                                       showChooseRoleModal
                                                     }) => {

  const topCategoriesLimited = topCategories?.slice(0, 6)

  const [subscribeEmail, setSubscribeEmail] = useState<string>("");

  const [subscribeEmailValidationMessage, setSubscribeEmailValidationMessage] = useState<string>("")

  const navigate = useNavigate();

  const isMobile = useMediaQuery({
    query: `(${device.mobileMax})`,
  });

  const dispatch = useDispatch();

  const performSearchByCategory = (name: string, code: number) => {
    addCategoryTag(name, code);
    filterData.categories = [code];

    dispatch({type: SET_SEARCH_BUTTON_CLICKED, searchButtonClicked: true});

    performSearch();
  }

  const signIn = useCallback(() => {

    isMobile ? navigate("/login-mobile") : showLogInModal();
  }, [isMobile]);

  const signUp = useCallback(() => {

    isMobile ? navigate("/signup-mobile-choose-role") : showChooseRoleModal();
  }, [isMobile]);

  function subscribe() {
    subscribeForNews({email: subscribeEmail})
      .then(()=>TeachingMeSuccessNotification(t("footer.subscribe.success-notification")))
      .catch(()=>TeachingMeErrorNotification(t("footer.subscribe.error-notification")))
      .finally(()=>setSubscribeEmail(""))
  }

  const {isSiderOpen} = useSiderContext()

  return <FooterWrapper isSiderOpen={isSiderOpen}>
    <div className="content-container">
    {!isCompactFooter && <div className="footer">

      {!isMobile &&
          <div className="logo-container" onClick={() => navigate(isTeacher ? "/user/profile" : "/")}>
              <WhiteLogoIcon/>
              <p className="logo-text">{t("teaching-me")}</p>
          </div>}
        <div className="menu">
            <h4>{t("footer.navigation-menu.header")}</h4>
          {(!isStudent && !isTeacher) ?
            <>
              <NavLink to="/">{t("footer.navigation-menu.home")}</NavLink>
              <p onClick={signIn}>{t("footer.navigation-menu.login")}</p>
              <p onClick={signUp}>{t("footer.navigation-menu.register")}</p>
              <FeedbackFooter t={t} title={t("footer.navigation-menu.feedback")}/>
            </>
            :
            <>
              {
                isStudent ?
                  <>
                    <NavLink to="/">{t("footer.navigation-menu.home")}</NavLink>
                    <NavLink to="/lessons">{t("footer.navigation-menu.lessons")}</NavLink>
                    <NavLink to="/favourites">{t("footer.navigation-menu.favourites")}</NavLink>
                    <NavLink to="/m">{t("footer.navigation-menu.messenger")}</NavLink>
                    <FeedbackFooter t={t} title={t("footer.navigation-menu.feedback")}/>
                  </>
                  :
                  <>
                    <NavLink to="/">{t("footer.navigation-menu.home")}</NavLink>
                    <NavLink to="/lessons">{t("footer.navigation-menu.lessons")}</NavLink>
                    <NavLink to="/user/settings">{t("footer.navigation-menu.settings")}</NavLink>
                    <NavLink to="/m">{t("footer.navigation-menu.messenger")}</NavLink>
                    <FeedbackFooter t={t} title={t("footer.navigation-menu.feedback")}/>
                  </>
              }
            </>
          }
        </div>
        <div className="menu">
            <h4>{t("footer.skill-menu")}</h4>
          {topCategoriesLimited && topCategoriesLimited.map(category => <p
            onClick={() => performSearchByCategory(category.name, category.code)}
            key={category.code}>{category.name}</p>)}
        </div>
        <div className="social-media-container">
            <p>{t("footer.follow-us")}</p>
            <div className="media">
                <a href="https://www.facebook.com/teaching.me.org">
                    <SocialMediaButton>
                        <FacebookFilled/>
                    </SocialMediaButton>
                </a>
                <a href="https://www.instagram.com/teaching_me_org">
                    <SocialMediaButton>
                        <InstagramFilled/>
                    </SocialMediaButton>
                </a>
                <a href="https://t.me/teaching_me_org">
                    <SocialMediaButton>
                        <TelegramIcon/>
                    </SocialMediaButton>
                </a>
            </div>
        </div>
        <div className="follow-us">
            <p>{t("footer.subscribe.header")}</p>
            <div className="input">
                <StyledSubscribeInput
                    placeholder={t("email-template")}
                    onChange={(e)=>setSubscribeEmail(e.target.value)}
                    value={subscribeEmail}
                    onBlur={(e)=>setSubscribeEmailValidationMessage(validateEmail(e.target.value, t))}
                    status={subscribeEmailValidationMessage ? "error" : ""}
                />
                <button onClick={subscribe} disabled={(!subscribeEmail || !!subscribeEmailValidationMessage)}>{t("footer.subscribe.btn")}</button>
            </div>
        </div>
    </div>}
    <div className="policy">
      <p>2024 @ Teaching-Me</p>
      {isCompactFooter && <div className="media">
          <a href="https://www.facebook.com/teaching.me.org">
              <SocialMediaButton>
                  <FacebookFilled/>
              </SocialMediaButton>
          </a>
          <a href="https://www.instagram.com/teaching_me_org">
              <SocialMediaButton>
                  <InstagramFilled/>
              </SocialMediaButton>
          </a>
          <a href="https://t.me/teaching_me_org">
              <SocialMediaButton>
                  <TelegramIcon/>
              </SocialMediaButton>
          </a>
      </div>}

      {!isMobile ?
        <div className="policy_terms">
          <p onClick={() => navigate("/privacy-policy")}>{t("footer.privacy-long")}</p>
          <p onClick={() => navigate("/terms-of-use")}>{t("footer.terms-long")}</p>
        </div>
        :
        <div className="policy_terms">
          <div>
            <p onClick={() => navigate("/privacy-policy")}>{t("footer.privacy-short")}</p>
            <span>&</span>
            <p onClick={() => navigate("/terms-of-use")}>{t("footer.terms-short")}</p>
          </div>
        </div>
      }

    </div>
    </div>
  </FooterWrapper>;
};