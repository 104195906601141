import styled from "styled-components"

export const LessonRequestHeaderStyled = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .requests-menu {
        border-bottom: none;
        max-width: 420px;
        flex: 1;
    }
    
    margin-bottom: 24px;
`

export const LessonRequestHeaderFilter = styled.div`
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    gap: 24px;
    flex: 1;

    > div {
        display: flex;
        gap: 12px; 
        align-items: center;
    }
`