import { FC, useMemo } from "react";

import { CustomLoader } from "../../../elements/custom-loader";
import { NotFoundComponent } from "../../../elements/not-found-component";

import LessonRequestCard from "../lesson-request-card/lesson-request-card";

import LessonRequestHeader from "../lesson-request-header/lesson-request-header";

import { StudentMenuTabs } from "../lesson-request";

import { UserLessonRequestProps } from "../teacher/teacher-lesson-request";

import { FAQMenu } from "../../../../types/FAQ";

enum ReqTabs {
  ACTIVE_REQUESTS = "active-requests",
  ALL_REQUESTS = "all-requests",
}

const SEARCH_MAPPING: Record<keyof StudentMenuTabs, boolean> = {
  all_requests: false,
  active_requests: false,
};

const FILTER_INPUT_MAPPING: Record<keyof StudentMenuTabs, [boolean, boolean]> =
  {
    active_requests: [false, false],
    all_requests: [true, false],
  };

const StudentLessonRequest: FC<UserLessonRequestProps> = ({
  t,
  isLoading,
  requests,
  selectedKey,
  handleChangeSelectedKey,
}) => {
  const selectedStudentKey = selectedKey as keyof StudentMenuTabs;

  const MENU_TITLES: FAQMenu<StudentMenuTabs>[] = [
    {
      id: 0,
      label: t(`lesson-requests.tabs.${ReqTabs.ACTIVE_REQUESTS}`),
      key: "active_requests",
    },
    {
      id: 1,
      label: t(`lesson-requests.tabs.${ReqTabs.ALL_REQUESTS}`),
      key: "all_requests",
    },
  ];

  const REQUEST_LIMIT_MAPPING: Record<keyof StudentMenuTabs, boolean> = {
    active_requests: true,
    all_requests: false,
  };

  const renderCards = useMemo(() => {
    if (isLoading) {
      return <CustomLoader />;
    }

    if (!requests || requests.length === 0) {
      return (
        <NotFoundComponent
          text={t("lesson-requests.not-found")}
          style={{ height: "60vh" }}
        />
      );
    }

    return (
      <div className="cards">
        {requests.map((request) => (
          <LessonRequestCard key={request.id} card={request} t={t} />
        ))}
      </div>
    );
  }, [isLoading, requests, t]);

  return (
    <>
      <LessonRequestHeader
        handleChangeSelectedKey={handleChangeSelectedKey}
        menuTitles={MENU_TITLES}
        selectedKey={selectedStudentKey}
        requestsLength={requests.length || 0}
        t={t}
        handleRenderFilterInputs={(key) => FILTER_INPUT_MAPPING[key]}
        showRequestLimit={(key) => REQUEST_LIMIT_MAPPING[key]}
        isShowSearchFn={(key) => SEARCH_MAPPING[key]}
      />
      {renderCards}
    </>
  );
};

export default StudentLessonRequest;
