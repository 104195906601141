import { useMemo } from "react";

interface UsePaginationProps {
  totalItems: number | undefined;
  pageSize: number;
  siblingCount?: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

interface UsePaginationReturn {
  totalPages: number;
  paginationRange: (string | number)[];
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  goToPage: (value: number | string) => void;
}

export const usePagination = ({
  totalItems = 1,
  pageSize,
  siblingCount = 1,
  currentPage,
  setCurrentPage,
}: UsePaginationProps): UsePaginationReturn => {
  const totalPages = Math.ceil(totalItems / pageSize);

  const boundaryCount = 1;

  const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  };

  const paginationRange = useMemo((): (string | number)[] => {
    const startPages = range(1, Math.min(boundaryCount, totalPages));
    const endPages = range(
      Math.max(totalPages - boundaryCount + 1, boundaryCount + 1),
      totalPages
    );

    const siblingsStart = Math.max(
      Math.min(
        currentPage - siblingCount - 1,
        totalPages - boundaryCount - siblingCount * 2 - 3
      ),
      boundaryCount + 2
    );

    const siblingsEnd = Math.min(
      Math.max(
        currentPage + siblingCount + 1,
        boundaryCount + siblingCount * 2 + 3
      ),
      endPages.length > 0 ? endPages[0] - 2 : totalPages - 1
    );

    return [
      ...startPages,
      ...(siblingsStart > boundaryCount + 2
        ? ["..."]
        : boundaryCount + 1 < totalPages - boundaryCount
        ? [boundaryCount + 1]
        : []),
      ...range(siblingsStart, siblingsEnd),
      ...(siblingsEnd < totalPages - boundaryCount - 1
        ? ["..."]
        : totalPages - boundaryCount > boundaryCount
        ? [totalPages - boundaryCount]
        : []),
      ...endPages,
    ];
  }, [boundaryCount, siblingCount, currentPage, totalPages]);

  const goToNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const goToPage = (value: number | string) => {
    if (typeof value === "number") {
      setCurrentPage(value);
    }
  };

  return {
    totalPages,
    paginationRange,
    goToNextPage,
    goToPreviousPage,
    goToPage,
  };
};
