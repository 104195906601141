import {GET_CHATS} from "../endpoints";
import axiosClient from "../../config/axiosConfig";
import {TMessageResponse} from "../../types";


export const createChat = async (languageCode: string, receiverId: string): Promise<TMessageResponse> => {
    const data = {receiverData: {id: receiverId}};

    return axiosClient.post(GET_CHATS(), data, {
        headers: {
            "Content-Type": "application/json",
            "Accept-Language": languageCode
        }
    });
}

