import styled from "styled-components";
import { device } from "../../../constants";

export const LessonRequestCardWrapper = styled.div`
  padding: 22px;
  width: 100%;
  border-radius: 16px;
  background: #FAFCFF;
`;

export const LessonRequestCardLine = styled.div`
  width: 52px;
  height: 1px;
  background: #d6e4ff;
  transform: rotate(90deg);
  margin: 0 auto;
`;

export const LessonRequestCardUserInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  height: 100%;

  .personal_info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    p {
      color: #000000;
      
    }

    > div {
      display: flex;
      align-items: center;
      gap: 8px;
      p {
        font-size: 16px;
        line-height: 24px;
        color: #000;
        font-weight: 500;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  img {
    max-width: 44px;
    max-height: 44px;
    border-radius: 100%;
  }

  svg {
    stroke: #18067D;
  }
`;

export const LessonRequestCardTimeInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  p {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      min-width: 16px;
    }
  }
`;

export const LessonRequestValidUntil = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 8px;
  }
`;

export const LessonRequestActionButtonsWrapper = styled.div`
  display: flex;
  gap: 18px;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

export const LessonRequestStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`

export const CardContainer = styled.div`
  width: 100%;
  display: grid;

  grid-template-columns: repeat(3, 2fr 1fr);
  white-space: nowrap;
  grid-auto-flow: column;
  align-items: center;
`

export const LessonRequestMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  p {
    white-space: break-spaces;
  }
`