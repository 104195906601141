import {TTeacherReview} from "./Categories";
import {TMeetingInfo} from "./Meeting";
import {TAttachment, TFile} from "./User";
import {IDrawboard} from "./Drawboard";
import {today} from "../services/date-service";
import { StudentStatuses } from "../components/pages/my-students/student-status/student-status";


export interface ITask {
    hour: string;
    task?: TTaskText | null,
    active?: boolean;
    disabled?: boolean;
}

export type TTaskText = {
    taskId: number | string;
    title: string;
    description: string;
};

export type TWorkDay = {
    isRepeatable: boolean;
    startDate: string;
    endDate: string;
}

export type TLessonQuery = {
    searchString?: string,
    dateFrom?: string | Date,
    dateTo?: string | Date,
    page: number,
    size: number,
    statuses?:string[]
}

export enum View {
    GRID = "grid",
    LIST = "list",
}

export enum LessonsType {
    ALL_LESSONS = "All lessons",
    UPCOMING = "Upcoming",
    IN_PROGRESS = "In progress",
    COMPLETED = "Completed",
}

export enum LessonsTypeUk {
    ALL_LESSONS = "Усі уроки",
    UPCOMING = "Майбутні уроки",
    IN_PROGRESS = "В процесі",
    COMPLETED = "Завершені",
}

export type TLessonsResponse = {
    data: {
        isLast: boolean;
        lessons: ILesson[];
        page: number;
        pageSize: number;
        totalElements: number;
    }
}

export type TLessonsByIdResponse = {
    data: ILessonById[];
}

export interface ILessonById {
    categoryName: string,
    id: string,
    imageId: string,
    startTime: string;
    studentId: string;
    title: string
    teacherName: string,
    studentName: string,
}

export enum EHomeworkStatus {
    CREATED = "CREATED",
    IN_REVIEW = "IN_REVIEW",
    DONE = "DONE",
    REWORK_REQUIRED = "REWORK_REQUIRED"
}

export enum ELessonStatus {
    CREATED = "CREATED",
    READY_TO_START = "READY_TO_START",
    MEETING_COMPLETED = "MEETING_COMPLETED",
    HOMETASK_ADDED = "HOMETASK_ADDED",
    HOMETASK_SUBMITTED = "HOMETASK_SUBMITTED",
    COMPLETED = "COMPLETED",
    CANCELED = "CANCELED",
}

export enum ETaskPriority {
    REQUIRED = "REQUIRED",
    OPTIONAL = "OPTIONAL",
}

export enum ELocationType {
    ONLINE = "ONLINE",
    OFFLINE = "OFFLINE",
}

export interface LessonsFilter {
    searchString: string;
    type: LessonsType;
    page: number;
}

export const initialLessonsFilter: LessonsFilter = {
    searchString: "",
    type: LessonsType.UPCOMING,
    page: 0,
}

export type TLessonDetailsResponse = {
    data: ILessonDetails;
}

export type TLessonParticipant = {
    id: string;
    fullName: string;
    avatarId: string;
}

export interface ILessonMainInfo {
    categoryName: string;
    endTime: string | Date;
    id: string;
    location: string;
    locationType: ELocationType;
    price: number;
    reviews: any[];
    startTime: string | Date;
    status: ELessonStatus;
    teacher: TLessonParticipant;
    student: TLessonParticipant;
    title: string;
    imageId: string;
    categoryId: string;
}

export interface ILesson extends ILessonMainInfo {
    meeting: TMeetingInfo | null;
}

export interface ILessonDetails extends ILessonMainInfo {
    allowedActions: EAllowedActions[];
    attachments: TFile[];
    description?: string;
    drawBoards: IDrawboard[];
    homeworkId: string | null;
    invoice: TInvoice | null;
    meeting: TMeetingInfo | null;
    createdBy: ECreatedBy
}

export enum ECreatedBy {
    TEACHER = "TEACHER",
    STUDENT = "STUDENT",
}

export enum EAllowedActions {
    APPROVE = "APPROVE",
    CANCEL = "CANCEL",
    EDIT = "EDIT",
    START_MEETING = "START_MEETING",
    JOIN_MEETING = "JOIN_MEETING",
    CONFIRM_PAYMENT = "CONFIRM_PAYMENT",
    UPLOAD_HOMEWORK_RESULT = "UPLOAD_HOMEWORK_RESULT",
    ADD_HOMEWORK = "ADD_HOMEWORK",
    EDIT_HOMEWORK = "EDIT_HOMEWORK",
    REVIEW_HOMEWORK = "REVIEW_HOMEWORK",
    COMPLETE = "COMPLETE",
    ADD_REVIEW = "ADD_REVIEW"
}

export type TInvoice = {
    amount: number;
    errorReason: string | null;
    id: string;
    invoiceState: EInvoiceState;
}

export enum EInvoiceState {
    CREATED = "CREATED",
    CAPTURED = "CAPTURED",
    AUTHORIZED = "AUTHORIZED",
    CANCELED = "CANCELED"
}

export interface LessonsFilter {
    searchString: string;
    type: LessonsType;
    page: number;
}

export interface ILessonReqBody {
    title: string;
    teacherId: string;
    studentId: string;
    image: string;
    lessonPrice: number;
    categoryId: string;
    locationType: ELocationType;
    location: string;
    startTime: string | Date;
}

export interface IOverview {
    id: string;
    title: string;
    imageId: string;
    description?: string;
    attachments: TFile[];
}

export interface IOverviewReqBody {
    id: string;
    imageId: string;
    title: string;
    description?: string;
    attachments: TAttachment[];
}

export type TLessonCreationResponse = {
    data: ICreatedLesson;
}

export interface ICreatedLesson {
    id: string;
    title: string;
    description?: string;
    teacherName: string;
    studentName: string;
    categoryName: string;
    price: number;
    startTime: string;
    endTime: string;
    attachments: TAttachment[];
}

export type TInvoicePayment = {
    data: IInvoicePayment;
}

export interface IInvoicePayment {
    invoiceId: string;
}

export type TCategoryPrice = {
    data: ICategoryPrice;
}

export interface ICategoryPrice {
    price: number;
}

export const initialReviewValue: TTeacherReview = {
    comment: "",
    rating: 1,
}

export type TReviewValidationRes = {
    data: IReviewValidation;
}

export interface IReviewValidation {
    isAllowed: boolean;
}

export type IHomeworkResponse = {
    data: IHomework;
}

export interface IHomework {
    acceptedDate: string | Date | null;
    createdDate: string | Date | null;
    description: string;
    dueDate: string | Date;
    finalMark: number | null;
    id?: string;
    reviewComment: string | null;
    status: EHomeworkStatus;
    student: TLessonParticipant;
    submittedDate: string | Date | null;
    tasks: TTask[];
    teacher: TLessonParticipant;
    title: string;
}

export const emptyHomework: IHomework = {
    title: "",
    acceptedDate: null,
    createdDate: null,
    description: "",
    dueDate: today,
    finalMark: null,
    reviewComment: null,
    status: EHomeworkStatus.CREATED,
    submittedDate: null,
    tasks: [],
    student: {
        id: "",
        fullName: "",
        avatarId: "",
    },
    teacher: {
        id: "",
        fullName: "",
        avatarId: "",
    },
}

export type TTaskBody = {
    taskPriority: ETaskPriority;
    description: string;
    title: string;
    attachments: string[];
}

export type TTask = {
    id?: string;
    title: string;
    status: EHomeworkStatus;
    taskPriority: ETaskPriority;
    description: string;
    resultComment?: string;
    createdDate?: string | Date | null;
    attachments: TFile[];
    verifications?: TVerification[];
}

export type TCreatedTask = {
    data: TTask;
}

export const emptyTask: TTask = {
    taskPriority: ETaskPriority.OPTIONAL,
    status: EHomeworkStatus.CREATED,
    description: "",
    title: "",
    attachments: [],
}

export type TCreateHomeworkResponse = {
    data: TCreateHomework;
}

export type TCreateHomework = {
    id?: string;
    title: string;
    description: string;
    dueDate: string | Date;
    status?: EHomeworkStatus;
    createdDate?: string | Date;
    tasks: TUpdateTask[];
}

export type TUpdateHomeworkResponse = {
    data: TUpdateHomework;
}

export type TUpdateHomework = {
    title: string;
    description: string;
    dueDate: string | Date;
}

export type TUpdateTask = {
    taskPriority: ETaskPriority;
    description: string;
    title: string;
    attachments: TAttachment[];
}

export type TVerification = {
    comment: string;
    attachments: TFile[];
    createdDate: string;
}

export type TUploadedAttachmentResponse = {
    data: TFile[];
}

export type TActiveStudentsResponse = {
    data: IActiveStudents[];
}

export interface IActiveStudents {
    id: string;
    avatarId: string;
    firstName: string;
    lastName: string;
    lastLessonTitle: string;
    lastLessonDate: Date | string;
}

export type TAllToDoHomeworksResponse = {
    data: IAllToDoHomeworks[];
}

export interface IAllToDoHomeworks {
    commentsCount: number,
    createdDate: Date | string
    description: string,
    donePercentage: number,
    dueDate: Date | string,
    id: string,
    lessonId: string;
    lessonImage: null,
    status: EHomeworkStatus,
    studentAvatar: string,
    studentName: string,
    submittedDate: null,
    tasksCount: number,
    teacherAvatar: string,
    teacherName: string,
    title: string,
}

export type TUserUpdateLessonSettingsRequest = {
    firstLessonFree: boolean;
}

export type TUserLessonSettingsResponse = {
    data: UserLessonSettings;
}

export interface UserLessonSettings {
    firstLessonFree: boolean;
}

export type TLessonsCountResponse = {
    data: ILessonsCount;
}

export interface ILessonsCount {
    count: number;
}

export enum Period {
    ALL = "ALL",
    MONTH = "MONTH"
}

export type TActiveSlot = {
    startDate: string,
    endDate: string
}

export type TConnectionRequestResponse = {
    data: TConnectionRequest
}

export type TConnectionRequest = {
    message: string,
    knowledgeLevel: KnowledgeLevels | undefined,
    age: string,
    numberOfClassesPerWeek: LessonsFrequency | undefined,
    "categoryId": string,
    "teacherId": string,
    "timeSlots": TActiveSlot[]
}

export enum LessonsTime {
    Morning = "morning",
    Afternoon = "afternoon",
    Evening = "evening",
    Night = "night",
}

export enum LessonsFrequency {
    ONE = "ONE",
    TWO = "TWO",
    ONE_TWO = "ONE_TWO",
    THREE = "THREE",
    TWO_THREE = "TWO_THREE",
    MORE = "MORE"
}

export enum LessonsFrequencyNames {
    ONE = "1",
    TWO = "2",
    ONE_TWO = "1-2",
    THREE = "3",
    TWO_THREE = "2-3",
    MORE = "3+"
}

export enum IPrices {
    ONE_THREE = "100-300",
    THREE_FIVE = "300-500",
    FIVE_SEVEN = "500-700",
    SEVEN_MORE = "700+",
}

export enum IAges {
    UNDER_TEN = "10",
    TEN_SIXTEEN = "10-16",
    SIXTEEN_TWENTYFIVE = "16-25",
    TWENTYFIVE_FORTY = "25-40",
    FORTY_MORE = "40+"
}

export enum KnowledgeLevels {
    BEGINNER = "BEGINNER",
    INTERMEDIATE = "INTERMEDIATE",
    ADVANCED = "ADVANCED",
    HIGH = "HIGH"
}

export type TLatestDirectResponse = {
    data: TLatestDirect
}

export type TLatestDirect = {
    activeRequestsCount: number,
    latestRequest : {
        categoryId: string,
        numberOfClassesPerWeek: LessonsFrequency,
        message: string,
        knowledgeLevel: KnowledgeLevels,
        age: string,
    }
}

export enum LessonRequestsStatuses {
    CREATED = "waiting-for-reply",
    ACCEPTED = "accepted",
    CANCELLED = "cancelled",
    DEACTIVATED = "deactivated"
}

export enum GetRequestsLessonStatuses {
    CANCELLED = "CANCELLED",
    CREATED = "CREATED",
    ACCEPTED = "ACCEPTED",
    DEACTIVATED = "DEACTIVATED",
}

export enum LessonRequestType {
    STUDENT_PUBLIC = "STUDENT_PUBLIC",
    TEACHER_PUBLIC = "TEACHER_PUBLIC", 
    STUDENT_DIRECT = "STUDENT_DIRECT",
}

export interface IGetConnectionRequests {
    categoryIds?: string[] | null;
    status?: GetRequestsLessonStatuses | null;
    type?: LessonRequestType | null;
    page?: number;
    size?: number;
}

export interface RequestLesson {
    age: string | null;
    applicants: RequestLessonApplicants[];
    categoryId: string;
    desiredTimeslots: [];
    id: string;
    knowledgeLevel: string | null;
    message: string | null;
    status: GetRequestsLessonStatuses;
    numberOfClassesPerWeek: LessonsFrequency;
    studentName: string | null;
    teacherId: string;
    teacherName: string | null;
    type: LessonRequestType;
    validUntil: Date;
    studentId: string;
    studentAvatarId: string;
    teacherAvatarId: string;
    desiredTime: LessonsTime;
    desiredPriceRange: IPrices;
    categoryName: string;
    categoryCode: string;
}

export interface RequestLessonApplicants {
    id: string,
    status: StudentStatuses,
    type: LessonRequestType,
    message: string,
    price: 0,
    applicantName: string,
    applicantId: string
}

export interface IGetConnectionReqeustsResponse {  
    data: {
        connectionRequests: RequestLesson[];
        pageSize: number;
        totalElements: number;
    };
    status: number;
}

export interface IPutConnectionRequests {
    message: "string",
    knowledgeLevel: KnowledgeLevels,
    numberOfClassesPerWeek: LessonsFrequency,
    desiredTime: LessonsTime,
    desiredPriceRange: IPrices
}

export interface IPutDirectLessonRequest {
    status: GetRequestsLessonStatuses,
}

export enum ApplicationStatuses {
    CANCELLED = "CANCELLED",
    ACTIVE = "ACTIVE",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
}