import { useState } from "react";
import {
  InputTextLabel,
  InputTextStyled,
  InputTextWrapper,
} from "./input-styled";
import { SearchIcon } from "../../../assets/images/svg-elements/search";

export interface InputProps {
  label?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: string | null;
  type: "number" | "text" | "password" | "email" | "search";
  onChange?: (value: string) => void;
  onEnterPress?: (value: string) => void;
}

const Input = ({
  label,
  type,
  defaultValue,
  disabled,
  placeholder,
  required,
  onChange,
  onEnterPress,
}: InputProps) => {
  const [value, setValue] = useState<string>(defaultValue || "");

  const handleEnterPress = (eventKey: string) => {
    if (!onEnterPress) return;

    if (eventKey === "Enter") {
      onEnterPress(value);
    }
  };

  const handleChange = (value: string) => {
    if (!onChange) return;

    setValue(value);
    onChange(value);
  };

  return (
    <InputTextWrapper>
      {label && <InputTextLabel>{label}</InputTextLabel>}
      <div>
        {type === "search" && <SearchIcon className="search-icon" />}
        <InputTextStyled
          value={value}
          type={type}
          onChange={(e) =>
            handleChange(e.target.value)
          }
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          onKeyDown={(e) => handleEnterPress(e.key)}
        />
      </div>
    </InputTextWrapper>
  );
};

export default Input;
