import styled from "styled-components";

export const UpcomingSlotStyled = styled.div`

    border-radius: 8px;
    padding: 8px;
    gap: 8px;
    background-color: #F5F5F5;
    border: 1px solid #D9D9D9;
    user-select: none;

    .time-block {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
    }
    
    &:hover {
        cursor: pointer;
    }
    
    &.active {
        border: 1px solid #5D51A4;
        background-color: #E8E6F2;
        font-weight: 500;
    }
`