import { FC } from "react";

import { ButtonTheme, StyledButton } from "../../../../../elements/button";

import { LessonRequestCardLine } from "../../../lesson-request-card/lesson-request-card-styled";

import { StudentDropdownCardWrapper } from "./student-dropdown-card-styled";

import {
  ApplicationStatuses,
  RequestLessonApplicants,
} from "../../../../../../types";
import { postAcceptApplicant } from "../../../../../../api/lesson-api/postAcceptApplicant";
import { putDeclineApplicant } from "../../../../../../api/lesson-api/putDeclineApplicant";
import { CardMessageButton } from "../../../lesson-request-action-buttons/lesson-request-action-buttons";
import { TType, useTranslate } from "../../../../../../assets/translations";
import { useNavigate } from "react-router-dom";

interface StudentDropdownCardProps {
  teacher: RequestLessonApplicants;
  connectionRequestId: string;
  studentId: string;
  t: TType
}

const StudentDropdownCard: FC<StudentDropdownCardProps> = ({
  teacher,
  connectionRequestId,
  studentId, 
  t
}) => {
  const {
    message,
    price,
    applicantName,
    status,
    type,
    id: applicationId,
    applicantId,
  } = teacher;

  const { language } = useTranslate();
  const navigate = useNavigate();

  const handleAcceptApplicant = async (
    connectionRequestId: string,
    applicationId: string
  ) => {
    try {
      const res = await postAcceptApplicant(
        connectionRequestId,
        applicationId,
        { status: ApplicationStatuses.ACCEPTED }
      );
    } catch (error) {}
  };

  const handleDeclineApplicant = async (
    connectionRequestId: string,
    applicationId: string,
    message: string,
    price: number
  ) => {
    try {
      const res = await putDeclineApplicant(
        connectionRequestId,
        applicationId,
        {
          status: ApplicationStatuses.REJECTED,
          price,
          message,
        }
      );
    } catch (error) {}
  };

  return (
    <StudentDropdownCardWrapper>
      <div className="personal_info">
        {/* <img src={getUsersPicture(teacherAvatarId)} alt="teacher-icon" /> */}
        <p>{applicantName}</p>
      </div>

      <LessonRequestCardLine />
      <p>{price} UAH</p>

      <LessonRequestCardLine />
      <p className="message">{message}</p>

      <LessonRequestCardLine />
      <div className="buttons">
        <StyledButton
          theme={ButtonTheme.BLUE}
          onClick={() =>
            handleAcceptApplicant(connectionRequestId, applicationId)
          }
        >
          Accept
        </StyledButton>
        <StyledButton
          theme={ButtonTheme.WHITE}
          onClick={() =>
            handleDeclineApplicant(
              connectionRequestId,
              applicationId,
              message,
              price
            )
          }
        >
          Decline
        </StyledButton>
        <CardMessageButton
          language={language}
          navigate={navigate}
          studentId={studentId}
          t={t}
          teacherId={applicantId}
          theme={ButtonTheme.INVERT_BLUE}
          type="icon"
        />
      </div>
    </StudentDropdownCardWrapper>
  );
};

export default StudentDropdownCard;
