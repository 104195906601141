import styled from 'styled-components';
import {Input} from "antd";

import {CaptionText} from "../../../ui-elements/text";
import {device} from "../../constants";


export const InputComponentWrapper = styled.div`
  .input-error-message {
    color: #ff4d4f;
    position: absolute;
    font-size: 14px;

    @media (${device.mobileMax}) {
      font-size: 11px;
    }

    @media (min-width: 320px) and (max-width: 374px) {
      font-size: 9px;
    }
  }

  @media (${device.mobileMax}) {
    .input-error-message {
      position: relative;
    }
  }
`

export const CaptionTextStyled = styled(CaptionText)`
  color: #BFBFBF;
`;

export const InputStyled = styled(Input)`
  border-radius: 8px;
  margin-top: 8px;
  height: 38px;

  .ant-input {
    font-weight: 400;
  }
`;
export const FormTitleWrapper = styled.div`
  margin-top: 20px;
`;
