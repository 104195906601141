import React, {FC} from "react";
import dayjs from "dayjs";

import {UpcomingSlotStyled} from "./upcoming-slot.styled";
import {TUpcomingSlot, TWeekDay} from "../../../../../types";
import {
  defineDaysDisplayRangeOnDateSelectWithViewDay,
  defineDaysDisplayRangeOnDateSelectWithViewWeek,
  defineDaysDisplayRangeOnDateSelectWithViewWeekForMobile,
  EViews,
  TDaysDisplayRange
} from "../../lesson-picker";
import {useNavigate} from "react-router-dom";
import {saveToLs} from "../../../../../services/storage-service";

interface Props {
  upcomingSlotData: TUpcomingSlot;
  activeSlots?: string[];
  setActiveSlots?: React.Dispatch<React.SetStateAction<string[]>>;
  setDaysDisplayRange: React.Dispatch<React.SetStateAction<TDaysDisplayRange>>;
  view: EViews;
  isMobile: boolean;
  daysData: TWeekDay[];
  setActiveSlotsLimitModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  goToConnectRequestPage?: boolean;
  teacherId?: string;
  roleTeacher: boolean;
}

export const UpcomingSlot: FC<Props> = ({upcomingSlotData, roleTeacher, teacherId, goToConnectRequestPage, setActiveSlotsLimitModalOpen, view, daysData, isMobile, setDaysDisplayRange, activeSlots, setActiveSlots}) => {

  const navigate = useNavigate();

  function handleOnClick() {

    if (goToConnectRequestPage) {
      saveToLs("activeSlots", [upcomingSlotData.startDate])
      navigate(`/connect-request/${teacherId}`);
      return;
    }

    activeSlots && setActiveSlots && setActiveSlots(prev=>{
      const slotAlreadyActive = prev.some(item => item === upcomingSlotData.startDate);

      if (slotAlreadyActive) {
        return [...prev.filter((item) => item !== upcomingSlotData.startDate)];
      }else {

        if(activeSlots.length >= 5) {
          setActiveSlotsLimitModalOpen(true);
          return prev;
        }

        if (view === EViews.WEEK) {
          if (isMobile) {
            setDaysDisplayRange(prev => defineDaysDisplayRangeOnDateSelectWithViewWeekForMobile(dayjs(upcomingSlotData.startDate), daysData, prev));
          } else {
            setDaysDisplayRange(prev => defineDaysDisplayRangeOnDateSelectWithViewWeek(dayjs(upcomingSlotData.startDate), daysData, prev));
          }
        } else {
          setDaysDisplayRange(prev => defineDaysDisplayRangeOnDateSelectWithViewDay(dayjs(upcomingSlotData.startDate), daysData, prev));
        }

        if (roleTeacher) {
          return [upcomingSlotData.startDate];
        }

        return [...prev, upcomingSlotData.startDate];
      }
    });
  }

  return(
    <UpcomingSlotStyled className={activeSlots && activeSlots.some(activeSlot=> activeSlot === upcomingSlotData.startDate) ? "active" : undefined}
                        onClick={handleOnClick}
    >
      <div className="time-block">{upcomingSlotData.startDateDisplayFormat}</div>
    </UpcomingSlotStyled>
  )
};