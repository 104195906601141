import styled from "styled-components";

interface SwitchProps {
  isActive: boolean;
}

export const SwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 9px;
`

export const SwitchStyled = styled.label<SwitchProps>`
  display: inline-block;
  width: 52px;
  border-radius: 100px;
  height: 30px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  user-select: none;
  margin-right: 5px;
  border: ${({ isActive }) => (isActive ? "none" : "1px solid #616161")};
  background: ${({ isActive }) => (isActive ? "#5D51A4" : "#fff")};

  .circle {
    position: absolute;
    top: 4px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: ${({ isActive }) => (isActive ? "#fff" : "#616161")};
    transition: 0.25s;
    z-index: 10;
    left: ${({ isActive }) => (isActive ? "calc(100% - 26px)" : "4px")};
  }

  input {
    display: none;
  }
`;
