import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getStudentProfileInfo, getTeacherProfileBasicInfo } from "../api/user-api";
import { RootState } from "../components/redux";
import { Authority, StudentProfile, TeacherProfile } from "../types";

export function useGetUser() {
  const { jwtParsed } = useSelector((state: RootState) => state.tokensStore);
  const [user, setUser] = useState<TeacherProfile | StudentProfile | null>(null);

  useEffect(() => {
    if (!jwtParsed?.authority) {
      return;
    }

    const isStudent = jwtParsed.authority === Authority.ROLE_STUDENT;


    (isStudent ? getStudentProfileInfo() : getTeacherProfileBasicInfo())
      .then((res) => {
        setUser(res.data);
      })
  }, [jwtParsed?.authority]);

  return {
    user,
    authority: jwtParsed?.authority,
  };
}
