import { FC } from "react";

import { isAxiosError } from "axios";
import { NavigateFunction } from "react-router-dom";

import { createChat, getChatByIds } from "../../../../api/chat-api";
import { putDirectLessonRequest } from "../../../../api/lesson-api/putDirectConnectionRequests";

import { Edit } from "../../../../assets/images/svg-elements/edit";
import { Translations, TType } from "../../../../assets/translations";
import { Letter } from "../../../../assets/images/svg-elements/letter";

import { ButtonTheme } from "../../../elements/button";
import {
  TeachingMeErrorNotification,
  TeachingMeSuccessNotification,
} from "../../../elements/notification";

import {
  LessonRequestActionButtonsWrapper,
  LessonRequestCardLine,
} from "../lesson-request-card/lesson-request-card-styled";

import {
  GetRequestsLessonStatuses,
  LessonRequestType,
} from "../../../../types";
import { ActionButtons } from "./lesson-request-action-buttons-styled";
import {
  createLessonDataKey,
  saveToLs,
} from "../../../../services/storage-service";

interface LessonRequestActionButtonsProps {
  isStudent: boolean;
  status: GetRequestsLessonStatuses;
  navigate: NavigateFunction;
  teacherId: string;
  studentId: string;
  language: Translations;
  connectionRequestId: string;
  t: TType;
  view?: "card" | "banner";
  categoryId?: string;
  type?: LessonRequestType;
  setIsOpenDropdown?: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenDropdown?: boolean;
}

interface CardMessageButtonProps {
  teacherId: string;
  studentId: string;
  navigate: NavigateFunction;
  language: Translations;
  theme: ButtonTheme;
  type?: "icon" | "text";
  t: TType;
}

export const CardMessageButton: FC<CardMessageButtonProps> = ({
  navigate,
  studentId,
  teacherId,
  language,
  theme,
  type,
  t,
}) => {
  const handleMessageButton = async () => {
    try {
      const res = await getChatByIds(teacherId, studentId);

      if (res?.data?.id) {
        navigate(`/m/${res.data.id}`);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        try {
          const newChatRes = await createChat(language, studentId);

          if (newChatRes?.data?.id) {
            navigate(`/m/${newChatRes.data.id}`, { state: newChatRes.data });
          } else {
            throw new Error("Chat creation failed");
          }
        } catch (error) {
          TeachingMeErrorNotification(t("errors.default"));
        }
      }
    }
  };

  return (
    <ActionButtons theme={theme} onClick={handleMessageButton}>
      {type === "icon" ? (
        <Letter />
      ) : (
        <span>{t("lesson-requests.button.message")}</span>
      )}
    </ActionButtons>
  );
};

const LessonRequestActionButtons: FC<LessonRequestActionButtonsProps> = ({
  isStudent,
  language,
  navigate,
  status,
  studentId,
  teacherId,
  connectionRequestId,
  t,
  view,
  categoryId,
  type,
  setIsOpenDropdown,
  isOpenDropdown
}) => {
  const handleActionRequest = async (
    status: GetRequestsLessonStatuses,
    connectionRequestId: string,
    successMessage: string
  ) => {
    try {
      const res = await putDirectLessonRequest(connectionRequestId, {
        status,
      });

      if (res.status === 200) {
        TeachingMeSuccessNotification(successMessage);
        saveToLs(createLessonDataKey, {
          teacherId,
          categoryId,
          studentId,
        });
        navigate(`/lesson/create`);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        TeachingMeErrorNotification(error.message);
      }
    }
  };

  console.log(isOpenDropdown);
  

  return (
    <>
      {isStudent ? (
        <LessonRequestActionButtonsWrapper>
          {view !== "banner" && <LessonRequestCardLine />}
          {status !== GetRequestsLessonStatuses.ACCEPTED ? (
            <>
              {setIsOpenDropdown && (
                <ActionButtons
                  theme={ButtonTheme.BLUE}
                  onClick={() => setIsOpenDropdown(!isOpenDropdown)}
                >
                  {isOpenDropdown ? "Hide applicants" : "Show applicants"}
                </ActionButtons>
              )}

              <ActionButtons theme={ButtonTheme.INVERT_BLUE}>
                <Edit />
              </ActionButtons>
            </>
          ) : (
            <CardMessageButton
              language={language}
              navigate={navigate}
              studentId={studentId}
              teacherId={teacherId}
              theme={ButtonTheme.INVERT_BLUE}
              type="icon"
              t={t}
            />
          )}
        </LessonRequestActionButtonsWrapper>
      ) : (
        <>
          {status === GetRequestsLessonStatuses.CREATED && (
            <LessonRequestActionButtonsWrapper>
              {view !== "banner" && <LessonRequestCardLine />}
              <ActionButtons
                theme={ButtonTheme.DARK_BLUE}
                onClick={() => {
                  if (type !== LessonRequestType.STUDENT_PUBLIC) {
                    handleActionRequest(
                      GetRequestsLessonStatuses.ACCEPTED,
                      connectionRequestId,
                      t("lesson-requests.notifications.approve")
                    );
                  } else if (setIsOpenDropdown) {
                    setIsOpenDropdown(true);
                  }
                }}
              >
                {t("lesson-requests.buttons.schedule")}
              </ActionButtons>

              <ActionButtons
                theme={ButtonTheme.INVERT_BLUE}
                onClick={() =>
                  type === LessonRequestType.STUDENT_PUBLIC &&
                  handleActionRequest(
                    GetRequestsLessonStatuses.CANCELLED,
                    connectionRequestId,
                    t("lesson-requests.notifications.decline")
                  )
                }
              >
                {t("lesson-requests.buttons.decline")}
              </ActionButtons>
              {view !== "banner" && (
                <CardMessageButton
                  language={language}
                  navigate={navigate}
                  studentId={studentId}
                  teacherId={teacherId}
                  theme={ButtonTheme.INVERT_BLUE}
                  type="icon"
                  t={t}
                />
              )}
            </LessonRequestActionButtonsWrapper>
          )}
        </>
      )}
    </>
  );
};

export default LessonRequestActionButtons;
