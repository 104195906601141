import React from "react";

import { MainLayoutStyled } from "./main-layout-styled";
import { useSiderContext } from "../../../context/SiderContext";
import { useRenderSider } from "../../../hooks/useRenderSider";
import { useMediaQuery } from "react-responsive";
import { device } from "../../constants";

interface MainLayoutProps {
  children: React.ReactNode;
  isAuthenticated: boolean;
}

const MainLayout = ({ children, isAuthenticated }: MainLayoutProps) => {
  const { isSiderOpen } = useSiderContext();
  const { shouldRenderProfileSider } = useRenderSider();
  const isDesktop = useMediaQuery({
    query: `(${device.desktop})`,
  });
  return (
    <MainLayoutStyled
      isSiderOpen={isSiderOpen}
      shouldRenderProfileSider={shouldRenderProfileSider}
      isAuthenticated={isAuthenticated}
      isDesktop={isDesktop}
    >
      {children}
    </MainLayoutStyled>
  );
};

export default MainLayout;
