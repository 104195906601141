import { Dispatch, SetStateAction } from "react";
import { SwitchStyled, SwitchWrapper } from "./switch-styled";

interface SwitchProps {
    label?: string;
    isActive: boolean;
    setIsActive: Dispatch<SetStateAction<boolean>>;
}

const Switch = ({label, isActive, setIsActive}: SwitchProps) => {
  return (
    <SwitchWrapper>
        {label && <p>{label}</p>}
        <SwitchStyled className="switch" isActive={isActive}>
            <input type="checkbox" onClick={() => setIsActive(!isActive)}/>
            <div className="circle" />
            <div className="bg" />
        </SwitchStyled>
    </SwitchWrapper>
  )
}

export default Switch