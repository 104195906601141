import React, { FC } from "react";

import { PaginationButtonStyled } from "./pagination-button-styled";

interface PaginationButtonProps {
  children: React.ReactNode;
  isActive?: boolean;
  handleClick: (value: number | string) => void;
  disabled?: boolean;
}

const PaginationButton: FC<PaginationButtonProps> = ({
  children,
  isActive,
  handleClick,
  disabled,
}) => {
  return (
    <PaginationButtonStyled
      onClick={() => handleClick(children as number | string)}
      disabled={disabled}
      className={isActive ? "pagination-button active" : "pagination-button"}
    >
      {children}
    </PaginationButtonStyled>
  );
};

export default PaginationButton;
