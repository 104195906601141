import styled from "styled-components";

import { GlassesIcon } from "../../../assets/images/svg-elements/glasses";
import { OpenBook } from "../../../assets/images/svg-elements/open-book";
import { StudyingHat } from "../../../assets/images/svg-elements/studying-hat";

import { IconColors } from "../../../types/Icons";

export const MenuButtonStyled = styled.button<{ isActive: boolean}>`
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex: 1;
    padding: 12px 0px;
    cursor: pointer;
    border: none;
    border-bottom: ${({isActive}) => (isActive ? "2px solid #5D51A4" : "none")};

    @media(min-width: 320px) and (max-width: 374px) {
        border-bottom: ${({isActive}) => (isActive ? "2px solid #5D51A4" : "1px solid #E5E5E6")};
    }

    & > span {
        color: ${({isActive}) => (isActive ? "#5D51A4" : "#595959")};
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    } 
`

export const MenuWrapperStyled = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 32px;
    border-bottom: 1px solid #E5E5E6;

    @media(min-width: 320px) and (max-width: 374px) {
        border-botton: none;
        flex-direction: column; 
    }
`

export const OpenBookIconStyled = styled(OpenBook)<{ stroke: IconColors}>`
    stroke: ${({ stroke }) => stroke};
`
export const StudyingHatIconStyled = styled(StudyingHat)<{ stroke: IconColors}>`
    stroke: ${({ stroke}) => stroke};
`
export const GlassesIconStyled = styled(GlassesIcon)<{ stroke: IconColors}>`
    stroke: ${({ stroke }) => stroke};
`