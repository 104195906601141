import {GET_SKILLS} from "../endpoints";
import axiosClient from "../../config/axiosConfig";
import {TUsersSkillResponse} from "../../types";


export const getUsersSkills = async (userId: string, languageCode: string): Promise<TUsersSkillResponse> => {
    return axiosClient.get(GET_SKILLS(userId), {
        headers: {
            "Accept-Language": languageCode
        }
    });
}

