import { useMemo, useState } from "react";

import { useSelector } from "react-redux";

import { TType, useTranslate } from "../../../assets/translations";

import Pagination from "../../elements/pagination/pagination";

import { usePagination } from "../../../hooks/usePagination";
import { useGetConnectionRequestsByType } from "../../../hooks/useGetConnectionRequests";

import WithSiderWrapper from "../../layout-components/wrappers/page-with-sider/page-with-sider";

import { RootState } from "../../redux";

import {
  ContentContainer,
  LessonRequestMainContainer,
  LessonRequestTitleContainer,
} from "./lesson-request-styled";

import { getPayload } from "./services/getPayload";

import StudentLessonRequest from "./student/student-lesson-request";

import TeacherLessonRequest from "./teacher/teacher-lesson-request";

import { Authority } from "../../../types";

export interface TeacherLessonMenuTabs {
  requests_from_students: string;
  opportunity_spot: string;
}

export interface StudentMenuTabs {
  active_requests: string;
  all_requests: string;
}

export type UnionTabsType = keyof (TeacherLessonMenuTabs & StudentMenuTabs);

const LessonRequestPage = ({ t }: { t: TType }) => {
  const {
    jwtParsed: { authority, user_id },
  } = useSelector((state: RootState) => state.tokensStore);

  const isStudent: boolean = authority === Authority.ROLE_STUDENT;

  const [selectedKey, setSelectedKey] = useState<UnionTabsType>(
    !isStudent ? "requests_from_students" : "active_requests"
  );

  const [currentPage, setCurrentPage] = useState(1);

  const [isSwitchActive, setIsSwitchActive] = useState(true);

  const { language } = useTranslate();

  const payload = useMemo(() => {
    return getPayload({
      selectedKey,
      isStudent,
      language,
      isSwitchActive,
      currentPage,
    });
  }, [selectedKey, isStudent, language, isSwitchActive, currentPage]);

  const { requests, isLoading, totalItems } =
    useGetConnectionRequestsByType(payload);

  const {
    goToNextPage,
    goToPage,
    goToPreviousPage,
    paginationRange,
    totalPages,
  } = usePagination({
    pageSize: 10,
    totalItems,
    currentPage,
    setCurrentPage,
  });

  const handleChangeSelectedKey = (key: UnionTabsType) => {
    setSelectedKey(key);
    goToPage(1);
  };

  const handleChangleIsSwitchActive = (state: boolean) => {
    setIsSwitchActive(state);
    goToPage(1);
  };

  return (
    <WithSiderWrapper background="#ECEFF2">
      <ContentContainer className="content-container">
        <LessonRequestTitleContainer>
          <h3>{t("lesson-requests.title")}</h3>
          <p>{t("lesson-requests.description")}</p>
        </LessonRequestTitleContainer>
        <LessonRequestMainContainer>
          {isStudent ? (
            <StudentLessonRequest
              t={t}
              requests={requests}
              isLoading={isLoading}
              isSwitchActive={isSwitchActive}
              setIsSwitchActive={handleChangleIsSwitchActive}
              selectedKey={selectedKey}
              handleChangeSelectedKey={handleChangeSelectedKey}
            />
          ) : (
            <TeacherLessonRequest
              t={t}
              requests={requests}
              isLoading={isLoading}
              isSwitchActive={isSwitchActive}
              setIsSwitchActive={handleChangleIsSwitchActive}
              selectedKey={selectedKey}
              handleChangeSelectedKey={handleChangeSelectedKey}
            />
          )}
        </LessonRequestMainContainer>
        {totalPages !== 0 && (
          <Pagination
            currentPage={currentPage}
            goToNextPage={goToNextPage}
            goToPage={goToPage}
            goToPreviousPage={goToPreviousPage}
            paginationRange={paginationRange}
            totalPages={totalPages}
          />
        )}
      </ContentContainer>
    </WithSiderWrapper>
  );
};

export default LessonRequestPage;
