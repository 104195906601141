import {useQuery} from "@tanstack/react-query";

import {QUERY_KEYS} from "../../config";
import {getUsersSkills} from "../../api/category-api";
import {TUsersSkillResponse} from "../../types";


interface Props<T = void> {
    teacherId: string;
    enabled?: boolean;
    select?: (response: TUsersSkillResponse) => T;
    language: string
}

export const useUsersSkills = <S>({select, enabled = true, teacherId, language}: Props<S>) => useQuery({
    queryKey: QUERY_KEYS.USER.skillsById(teacherId),
    queryFn: () => getUsersSkills(teacherId, language),
    enabled,
    select,
});