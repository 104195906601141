import styled from "styled-components";

export const NotAvailableSlotStyled = styled.div`

        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #E8E6F2;
        padding: 8px;
        border-radius: 8px;
        flex-grow: 1;
        row-gap: 32px;
        font-size: 12px;

        .status {
            color: #8C8C8C;
            font-weight: 600;
        }
    
        .content {
            max-width: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            row-gap: 4px;
            flex-grow: 1;
            
            svg {
                height: 24px;
                width: 24px;
                color: black;
                stroke: black;
            }
        }
`