import { isAxiosError } from "axios";
import { TType } from "../../../../../assets/translations";
import { FC, useState } from "react";
import { postUserApplications } from "../../../../../api/user-api/postUserApplication";
import {
  TeachingMeErrorNotification,
  TeachingMeSuccessNotification,
} from "../../../../elements/notification";
import {
  TeacherDropdownForm,
  TeacherDropdownWrapper,
} from "./teacher-dropdown-styled";
import Input from "../../../../elements/input/input";
import TextArea from "../../../../elements/textarea/textarea";
import { ButtonTheme, StyledButton } from "../../../../elements/button";

interface LessonRequestDropdownProps {
  t: TType;
  connectionRequestId: string;
  setIsOpenDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

function generateHintForEmptyFields(t: TType, message: string, price: number) {
  return (
    <>
      <p>
        {t(
          "create-lesson-page.create-lesson-form.create-lesson-button.disable-hint.title"
        )}
      </p>
      <ul>
        {!message && (
          <li>
            {t(
              "create-lesson-page.create-lesson-form.create-lesson-button.disable-hint.student"
            )}
          </li>
        )}
        {!price && (
          <li>
            {t(
              "create-lesson-page.create-lesson-form.create-lesson-button.disable-hint.currency"
            )}
          </li>
        )}
      </ul>
    </>
  );
}

const formDataEmpty = {
  message: "",
  price: 0,
};

const TeacherDropdown: FC<LessonRequestDropdownProps> = ({
  t,
  connectionRequestId,
  setIsOpenDropdown,
}) => {
  const [formData, setFormData] = useState(formDataEmpty);

  const handleChangeMessage = (value: string) => {
    setFormData((prev) => ({ ...prev, message: value }));
  };

  const handleChangePrice = (value: string) => {
    setFormData((prev) => ({ ...prev, price: +value }));
  };

  const disableCondition = !formData.message || !formData.price;

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const res = await postUserApplications(connectionRequestId, formData);

      if (res.status === 200) {
        setIsOpenDropdown(false);
        TeachingMeSuccessNotification(
          "You have been sucessfully applied to this application"
        );
      }
    } catch (error) {
      if (isAxiosError(error)) {
        const errorMessage = error.response?.data.message;
        TeachingMeErrorNotification(errorMessage);
      }
    }
  };

  const handleCancel = () => {
    setFormData(formDataEmpty);
    setIsOpenDropdown(false);
  };

  return (
    <TeacherDropdownWrapper>
      <TeacherDropdownForm onSubmit={handleSubmitForm}>
        <div className="inputs">
          <Input
            type="number"
            onChange={handleChangePrice}
            label="Price"
            placeholder="Enter your price"
            required
          />
          <TextArea
            onChange={handleChangeMessage}
            label="Message"
            placeholder="Enter your message"
            required
          />
        </div>
        <div className="buttons">
          <StyledButton
            theme={ButtonTheme.BLUE}
            disabled={disableCondition}
            hintForDisabledButton={generateHintForEmptyFields(
              t,
              formData.message,
              formData.price
            )}
            type="submit"
          >
            Send
          </StyledButton>
          <StyledButton
            theme={ButtonTheme.WHITE}
            type="button"
            onClick={handleCancel}
          >
            Cancel
          </StyledButton>
        </div>
      </TeacherDropdownForm>
    </TeacherDropdownWrapper>
  );
};

export default TeacherDropdown;
