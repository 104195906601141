import { AxiosResponse } from "axios";

import axiosClient from "../../config/axiosConfig";

import { POST_ACCEPT_APLICANT } from "../endpoints";

import { ApplicationStatuses } from "../../types";

interface PostAcceptApplicantBody {
    status: ApplicationStatuses;
} 

export const postAcceptApplicant = async (connectionRequestId: string, applicationId: string, body: PostAcceptApplicantBody): Promise<AxiosResponse> => {
    return axiosClient.post(POST_ACCEPT_APLICANT(connectionRequestId, applicationId), body, {
        headers: {
            "Content-Type": "application/json"
        }
    });
};