import { AxiosResponse } from "axios";

import axiosClient from "../../config/axiosConfig";

import { PUT_DECLINE_APLICANT } from "../endpoints";

import { ApplicationStatuses } from "../../types";

interface PutDeclineApplicant {
  status: ApplicationStatuses;
  price: number;
  message: string;
}

export const putDeclineApplicant = async (
  connectionRequestId: string,
  applicationId: string,
  body: PutDeclineApplicant
): Promise<AxiosResponse> => {
  return axiosClient.put(
    PUT_DECLINE_APLICANT(connectionRequestId, applicationId),
    body
  );
};
