import {TeachingMeErrorNotification} from "../components/elements/notification";


export const tokenKey = "token";

export const refreshTokenKey = "refresh";

export const logoutKey = "logoutInfo";

export const logoutClickKey = "logoutClick";

export const activeSlotsKey = "activeSlots";

export const createLessonDataKey = "createLessonData";

const saveToLs = (key: string, value: any, errText?: string) => {
    try {
        const serializedState = JSON.stringify(value);
        localStorage.setItem(key, serializedState);
    } catch (error: any) {
        errText && TeachingMeErrorNotification(errText);
        console.error(error.message);
    }
};

const loadFromLs = (key: string, errText?: string) => {
    try {
        const serializedState = localStorage.getItem(key);
        return serializedState === null ? undefined : JSON.parse(serializedState);
    } catch (error: any) {
        errText && TeachingMeErrorNotification(errText);
        console.error(error.message);
    }
};

const removeFromLS = (key: string) => localStorage.removeItem(key);

export {
    saveToLs,
    loadFromLs,
    removeFromLS
};