import axios from "axios";
import {Store} from "redux";
import jwt_decode from "jwt-decode";

import {RootState, updateTokens} from "../components/redux";
import {postRefreshToken} from "../api/user-api";
import {TJwtTokenEncoded} from "../types";
import {loadFromLs, logoutKey, refreshTokenKey, saveToLs, tokenKey} from "../services/storage-service";
import {sendAnalyticEvent} from "../services/user-service";


const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

let store: Store<RootState>;

export const injectStoreToInterceptors = (_store: Store<RootState>) => {
    store = _store
}

axiosClient.interceptors.request.use(config => {
    const token = loadFromLs(tokenKey);

    if (!config.url!.includes("/open")) {
        config.headers!.Authorization = `Bearer ${token}`
    }

    return config;
}, error => Promise.reject(error));

axiosClient.interceptors.response.use(response => {

        if (!response.request.responseURL.includes("/open") && !response.request.responseURL.includes("/users/delete")) {
            const refreshToken = loadFromLs(refreshTokenKey);

            if (refreshToken) {
                postRefreshToken({refreshToken})
                  .then(({data}) => {
                      saveToLs(tokenKey, data.token);

                      const jwtParsed: TJwtTokenEncoded = jwt_decode(data.token);

                      store.dispatch(updateTokens({token: data.token, jwtParsed}))
                  })
            }else {
                console.warn("No refreshToken available, refresh request was aborted")
            }
        }

        return response;
    },
    error => {
        if (error.response.status === 401 && store.getState().tokensStore.isAuthenticated) {
            saveToLs(logoutKey, true);
            window.location.pathname = "/logout";

            return Promise.reject(error);
        }

        const url = error.request.responseURL.split(process.env.REACT_APP_API_BASE_URL)[1];

        const message = error.message;

        if (error.response.status >= 500) {
            sendAnalyticEvent("server errors", {[url]: message})

            return Promise.reject(error);
        }

        sendAnalyticEvent("errors", {[url]: message})

        return Promise.reject(error);
    });


export default axiosClient;