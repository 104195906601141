import React, { FC } from "react";
import { WithSiderWrapperStyled } from "./page-with-sider-styled";
import { useSiderContext } from "../../../../context/SiderContext";
import { useRenderSider } from "../../../../hooks/useRenderSider";
import { useMediaQuery } from "react-responsive";
import { device } from "../../../constants";
import { loadFromLs, tokenKey } from "../../../../services/storage-service";

interface WithSiderWrapperProps {
  children: React.ReactNode;
  background?: string;
  noPaddings?: boolean;
}

const WithSiderWrapper: FC<WithSiderWrapperProps> = ({
  children,
  background,
  noPaddings,
}) => {
  const { isSiderOpen } = useSiderContext();
  const { shouldRenderProfileSider } = useRenderSider();
  const isDesktop = useMediaQuery({
    query: `(${device.desktop})`,
  });
  const isAuthenticated = !!loadFromLs(tokenKey);
  
  return (
    <WithSiderWrapperStyled
      background={background}
      noPaddings={noPaddings}
      isSiderOpen={isSiderOpen}
      shouldRenderProfileSider={shouldRenderProfileSider}
      isAuthenticated={isAuthenticated}
      isDesktop={isDesktop}
    >
      <div className="outer-container">{children}</div>
    </WithSiderWrapperStyled>
  );
};

export default WithSiderWrapper;
