import React, { ErrorInfo } from "react";

import { ErrorBoundary } from "react-error-boundary";

import { errorExceptions } from "../api/errorBoundary";

import { TType, useTranslate } from "../assets/translations";

import { ErrorPage, ErrorPageTypes } from "../components/pages";

import { isLocal } from "../utils/checkIsLocal";

export const CustomErrorBoundary = ({children}: {children: React.ReactNode}) => {
    const {t} = useTranslate()

    const sendErrorToServer = (error: Error, info: ErrorInfo) => {
        if (isLocal()) return;

        const errorMessage = formatErrorMessage(error, info);
        errorExceptions(errorMessage);
    }

    return (<ErrorBoundary onError={sendErrorToServer} FallbackComponent={() => <CustomErrorPage t={t} type={ErrorPageTypes.SOMETHING_WRONG}/>}>
        {children}
    </ErrorBoundary>)
}

const CustomErrorPage = ({t, type} : {t: TType, type: ErrorPageTypes}) => {
    return (
        <div style={{position: 'absolute', top: '50%', left: '50%', transform: "translate(-50%, -50%)"}}>
            <ErrorPage t={t} type={type}/>
        </div>
    )
}

function formatErrorMessage(error: Error, info: ErrorInfo): string {
    const { componentStack } = info;
    const { message, stack } = error;

    const lines = [
        `Error Message: ${message}`,
        stack ? `JavaScript Stack Trace:\n${stack}` : null,
        componentStack ? `React Component Stack:\n${componentStack}` : null,
        `User Agent: ${navigator.userAgent}`,
        `Current URL: ${window.location.href}`,
    ].filter(Boolean);

    return lines.join("\n\n");
}