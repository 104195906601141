import styled from "styled-components";
import {DateIcon} from "../../../../../assets/images/svg-elements/date";
import {device} from "../../../../constants";

export const PrimaryDateStyled = styled.div`
  
    display: flex;
    gap: 8px;
    align-items: center;
  
    .date-container {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #1F1F1F;
    }
    
    @media (${device.tabletMax}) {
        .date-container {
            font-size: 20px;
        }
    }
`

export const DateIconStyled = styled(DateIcon)`
    height: 14px;
    width: 14px;
`