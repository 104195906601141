import { FC, ReactNode, memo, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";

import { TType, useTranslate } from "../../../../assets/translations";
import { FieldTime } from "../../../../assets/images/svg-elements/field-time";
import ApproveBadge from "../../../../assets/images/svg/SafetyCertificate.svg";
import { CalendarClearIcon } from "../../../../assets/images/svg-elements/calendar-clear";

import { Hint } from "../../../elements/hint";

import LessonRequestActionButtons from "../lesson-request-action-buttons/lesson-request-action-buttons";

import LessonRequestStatus from "../lesson-request-status/lesson-request-status";

import { RootState } from "../../../redux";

import {
  CardContainer,
  LessonRequestCardLine,
  LessonRequestCardTimeInfo,
  LessonRequestCardUserInfo,
  LessonRequestCardWrapper,
  LessonRequestMessage,
  LessonRequestStatusWrapper,
  LessonRequestValidUntil,
} from "./lesson-request-card-styled";

import {
  getCategoryIcon,
  getUsersPicture,
} from "../../../../services/user-service";
import { getMonthNameWithDate } from "../../../../services/date-service";

import {
  Authority,
  GetRequestsLessonStatuses,
  LessonRequestType,
  LessonsFrequency,
  LessonsFrequencyNames,
  RequestLesson,
  TeacherDetails,
} from "../../../../types";
import { OpenBook } from "../../../../assets/images/svg-elements/open-book";
import CategoryFallback from "../../../../assets/images/png/category-fallback.png";
import TeacherDropdown from "../lesson-request-dropdown/teacher-dropdown/teacher-dropdown";
import StudentDropdown from "../lesson-request-dropdown/student-dropdown/student-dropdown";

interface LessonRequestCardProps {
  card: RequestLesson;
  t: TType;
  view?: "card" | "banner";
}

const renderInfoSection = (
  type: LessonRequestType,
  card: RequestLesson,
  isStudent: boolean,
  view: "card" | "banner",
  categoryName: string,
  categoryCode: string,
  teacher?: TeacherDetails
): ReactNode => {
  const renderCategoryInfo = (
    categoryName: string,
    isStudent: boolean,
    userName: string
  ) => {
    return (
      <>
        {view !== "banner" ? (
          <img
            src={getCategoryIcon(categoryCode) || CategoryFallback}
            alt={`open-book icon`}
          />
        ) : (
          <OpenBook />
        )}
        <div className="personal_info">
          {!isStudent && view !== "banner" && (
            <div>
              <p>{userName}</p>
            </div>
          )}
          <p>{categoryName}</p>
        </div>
      </>
    );
  };

  const renderUserInfo = (
    userName: string,
    isStudent: boolean,
    categoryName?: string
  ) => (
    <>
      <img
        src={getUsersPicture(
          isStudent ? card.teacherAvatarId : card.studentAvatarId
        )}
        alt="user avatar"
      />
      <div className="personal_info">
        <div>
          <p>{userName}</p>
          {teacher?.verifiedTeacher && (
            <img src={ApproveBadge} alt="approve badge" />
          )}
        </div>
        {categoryName && <p>{categoryName}</p>}
      </div>
    </>
  );

  if (type === LessonRequestType.STUDENT_PUBLIC || view === "banner") {
    return card.studentName
      ? renderCategoryInfo(categoryName, isStudent, card.studentName)
      : null;
  } else if (type === LessonRequestType.STUDENT_DIRECT && view === "card") {
    if (isStudent) {
      return card.teacherName
        ? renderUserInfo(card.teacherName, isStudent, categoryName)
        : null;
    } else {
      return card.studentName
        ? renderUserInfo(card.studentName, isStudent, categoryName)
        : null;
    }
  } else {
    return null;
  }
};

const LessonRequestCard: FC<LessonRequestCardProps> = memo(
  ({ card, t, view = "card" }) => {
    const {
      status,
      type,
      desiredTimeslots,
      numberOfClassesPerWeek,
      teacherId,
      validUntil,
      studentId,
      desiredTime,
      id,
      message,
      categoryName,
      categoryCode,
      categoryId,
      applicants
    } = card;

    const {
      jwtParsed: { authority },
    } = useSelector((state: RootState) => state.tokensStore);

    const isStudent = authority === Authority.ROLE_STUDENT;
    const { language } = useTranslate();
    const navigate = useNavigate();

    const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);

    const timesBlockRenderCondition = desiredTimeslots.length || desiredTime;

    return (
      <LessonRequestCardWrapper className={view}>
        <CardContainer className="container">
          <LessonRequestCardUserInfo>
            {renderInfoSection(
              type,
              card,
              isStudent,
              view,
              categoryName,
              categoryCode
            )}
          </LessonRequestCardUserInfo>

          <LessonRequestCardLine />
          <LessonRequestCardTimeInfo>
            {timesBlockRenderCondition ? (
              <p>
                <FieldTime />{" "}
                {desiredTimeslots.length
                  ? desiredTimeslots.join(",")
                  : desiredTime}
              </p>
            ) : (
              <p>
                <FieldTime /> {t("my-students.not-specified")}
              </p>
            )}
            {numberOfClassesPerWeek ? (
              <p>
                <CalendarClearIcon />{" "}
                {LessonsFrequencyNames[numberOfClassesPerWeek]}{" "}
                {numberOfClassesPerWeek === LessonsFrequency.ONE
                  ? t("main-page.search-query.one_time_per_week")
                  : t("main-page.search-query.time_per_week")}
              </p>
            ) : (
              <p>
                <CalendarClearIcon /> {t("my-students.not-specified")}
              </p>
            )}
          </LessonRequestCardTimeInfo>

          <LessonRequestCardLine />
          <LessonRequestValidUntil>
            <div>
              <p>{t("lesson-requests.valid-until")}</p>
              <Hint
                placement="topRight"
                title={t("lesson-requests.valid-until-hint")}
              >
                <InfoCircleOutlined />
              </Hint>
            </div>
            <p>
              {new Date(validUntil).getFullYear()},{" "}
              {getMonthNameWithDate(validUntil, language, true)}
            </p>
          </LessonRequestValidUntil>

          {view === "banner" && (
            <>
              <LessonRequestCardLine />
              <LessonRequestMessage>
                <p>{message}</p>
              </LessonRequestMessage>
            </>
          )}
          {(status && isStudent) ||
            (status &&
              !isStudent &&
              (type === LessonRequestType.STUDENT_PUBLIC ||
                type === LessonRequestType.STUDENT_DIRECT) &&
              status !== GetRequestsLessonStatuses.CREATED && (
                <>
                  <LessonRequestCardLine />
                  <LessonRequestStatusWrapper>
                    <LessonRequestStatus status={status} t={t} />
                  </LessonRequestStatusWrapper>
                </>
              ))}
          {view !== "banner" && (
            <LessonRequestActionButtons
              isStudent={isStudent}
              language={language}
              navigate={navigate}
              status={status}
              studentId={studentId}
              teacherId={teacherId}
              connectionRequestId={id}
              t={t}
              view={view}
              categoryId={categoryId}
              setIsOpenDropdown={setIsOpenDropdown}
              isOpenDropdown={isOpenDropdown}
              type={type}
            />
          )}
        </CardContainer>
        {view === "banner" && status === GetRequestsLessonStatuses.CREATED && (
          <LessonRequestActionButtons
            isStudent={isStudent}
            language={language}
            navigate={navigate}
            status={status}
            studentId={studentId}
            teacherId={teacherId}
            connectionRequestId={id}
            t={t}
            view={view}
            categoryId={categoryId}
          />
        )}
        {isOpenDropdown && !isStudent && (
          <TeacherDropdown
            t={t}
            connectionRequestId={id}
            setIsOpenDropdown={setIsOpenDropdown}
          />
        )}
        {isOpenDropdown && isStudent && <StudentDropdown card={card} t={t} />}
      </LessonRequestCardWrapper>
    );
  }
);

export default LessonRequestCard;
