import {CHATS_BY_USER_IDS} from "../endpoints";
import axiosClient from "../../config/axiosConfig";
import {TMessageResponse} from "../../types";


export const getChatByIds = async (teacherId: string, studentId: string): Promise<TMessageResponse> => {
    return axiosClient.get(CHATS_BY_USER_IDS(), {
        params: {
            userIds: `${teacherId}, ${studentId}`
        }
    });
}
