import styled, { keyframes, css } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const InputSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

export const InputSelectLabel = styled.label`
  font-weight: 500;
`;

export const DropdownWrapper = styled.div<{ isOpen: boolean }>`
  position: relative;
  width: 100%;

  .arrow,
  .search {
    path {
      fill: #d9d9d9;
    }
    stroke: #d9d9d9;
    position: absolute;
    top: 35%;
    right: 12px;
    pointer-events: none;
    width: 12px;
    height: 12px;
  }
`;

export const DropdownButton = styled.input<{
  isOpen: boolean;
  isPlaceholder: boolean;
}>`
  width: 100%;
  padding: 8px 30px 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  text-align: left;
  color: ${({ isPlaceholder, isOpen }) =>
    isPlaceholder || isOpen ? "rgba(0, 0, 0, 0.25)" : "black"};
  flex: 1;

  &:focus {
    outline: none;
  }
`;

export const DropdownList = styled.ul<{ isOpen: boolean }>`
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background: white;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  padding: 0;
  margin: 0;
  list-style: none;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  animation: ${({ isOpen }) =>
    isOpen
      ? css`
          ${fadeIn} 0.2s ease-in-out forwards;
        `
      : "none"};
`;

export const DropdownItem = styled.li<{ disabled?: boolean }>`
  padding: 8px 12px;
  cursor: pointer;
  color: black;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? "0.8" : "1")};

  &:hover {
    background-color: #f0f0f0;
  }
`;
