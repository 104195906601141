import styled from "styled-components";

export const SlotsBoardStyled = styled.div`

    border: 1px solid #D9D9D9;
    border-radius: 8px;
    display: flex;
    overflow: hidden;
    
    &> :last-child {
        .date-container {
            border-right: none;
        }
        
        .slots-container {
            padding-right: 8px;
        }
    }
    
    &> :first-child {
        .slots-container {
            padding-left: 8px;
        }
    }
`