import {FC, useMemo} from "react";

import {Hint} from "../hint";
import {useTranslate} from "../../../assets/translations";
import {Offline, Online} from "./online-status.styled";
import {getLastActiveTime, isUserOnline} from "../../../services/user-service";


interface Props {
    lastActiveTime: string | null;
    size?: string;
}

export const OnlineStatus: FC<Props> = ({lastActiveTime, size = "1vh"}) => {

    const {t, language} = useTranslate();

    const isOnline = useMemo(() => isUserOnline(lastActiveTime!), [lastActiveTime])


    if (!lastActiveTime) {
        return null;
    }

    return <Hint title={getLastActiveTime(lastActiveTime, t, language)}>
        {isOnline ? <Online size={size}/> : <Offline size={size}/>}
    </Hint>
};