import styled from "styled-components";
import {
  InputSelectLabel,
  InputSelectWrapper,
} from "../input-select/input-select-styled";

export const InputTextWrapper = styled(InputSelectWrapper)`
  position: relative;
  width: 100%;

  .search-icon {
    stroke: #d9d9d9;
    path {
      fill: #d9d9d9;
    }
    position: absolute;
    top: 35%;
    right: 12px;
    width: 12px;
    height: 12px;
  }
`;

export const InputTextLabel = styled(InputSelectLabel)``;

export const InputTextStyled = styled.input<{ type: "number" | "text" | "password" | "email" | "search" }>`
  width: 100%;
  padding: ${({ type }) => type === "search" ? "8px 24px 8px 12px" : "8px 12px"};
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  text-align: left;

  flex: 1;

  &:focus {
    outline: none;
  }
`;
