import styled from "styled-components";

export const TeacherDropdownWrapper = styled.div`
    padding: 24px;
`  

export const TeacherDropdownForm = styled.form`
    display: flex;
    
    .inputs {
        display: flex;
        align-items: flex-start;
        gap: 24px;
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
    }

    .input, .buttons {
        flex: 1;
    }
`