import styled from "styled-components";

export const StepTwoStyled = styled.div`

    display: flex;
    gap: 24px;

    .request-form {
        width: 70%;
        padding: 24px;
        background-color: #FFFFFF;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .input-wrapper {
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;

            }
        }

        .row-container {
            display: flex;
            column-gap: 24px;

            .input-wrapper, .custom-input-wrapper {
                width: 100%;

            }

            .custom-input-wrapper {
                > div {
                    margin: 0;
                }
            }
        }
    }

    .info-section {
        width: 30%;
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        .requests-info-container {
            padding: 24px;
            border-radius: 16px;
            background-color: #FFFFFF;
            display: flex;
            flex-direction: column;
            gap: 12px;

            .header {
                display: flex;
                align-items: center;

                .number-display {
                    background-color: #F9CF8B;
                    height: 58px;
                    width: 58px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 32px;
                    margin-right: 12px;
                    transition: 600ms;

                    &.max-requests {
                        background-color: #c91d1d;
                    }
                }

                h2 {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 32px;
                }
            }

            .description {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: #595959;
            }
        }
    }
`