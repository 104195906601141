export const colors = {
  labelGrey: '#8B93A2',
  lightGrey: '#949CAD',
  lighterGrey: '#979797',
  superLightGray: '#F4F6FA',
  white: '#fff',
  primaryColor: '#000000',
  textColorSecondary: '#8C8C8C',
  blue: '#0145da',
  lightBlue: '#5ab8fc',
  orange: '#f14a04',
  accentOrange: '#fc4c02',
  accentPrimary: '#384752',
  jungle: '#00909e',
  skySecondary: '#00A5B5',
  mapCluster: '#001E60',
  red: '#f5222d',
  green: '#05965e',
  polarGreen: '#52C41A',
  nature: '#00AC69',
  sand: '#D7A461',
};

