import styled from "styled-components";

import { Chevron } from "../../../assets/images/svg-elements/chevron";

export interface NavSiderStyledProps {
  collapsed?: string | undefined;
  opened?: string | undefined;
}

export const NavSider = styled.aside<NavSiderStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-right: 1px solid rgba(0, 0, 0, 10%);
  padding: 24px 0px;
  text-wrap: nowrap;
  position: fixed;
  top: 0;
  align-self: stretch;
  z-index: 1000;
  margin-top: var(--header-offset);
  height: calc(100vh - var(--header-offset));

  width: ${({ collapsed }) =>
    collapsed ? "var(--sider-collapsed-width)" : "var(--sider-opened-width)"};
  max-width: ${({ collapsed }) =>
    collapsed ? "var(--sider-collapsed-width)" : "var(--sider-opened-width)"};
  min-width: ${({ collapsed }) =>
    collapsed ? "var(--sider-collapsed-width)" : "var(--sider-opened-width)"};

  transition: all 0.3s ease-out;
`;

export const NavSiderInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 80%
`;

export const NavInfoSider = styled.div<NavSiderStyledProps>`
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0px 19px;
  min-height: fit-content;

  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin: 0px 24px 0px 0px;
  }

  p {
    font-weight: 500;
    color: rgba(0, 0, 0, 85%);
    visibility: ${({ collapsed }) => (collapsed ? "hidden" : "visible")};
    transition-delay: ${({ collapsed }) => collapsed ? "0" : "0.2s"};
  }
`;

export const NavDividerSider = styled.div<NavSiderStyledProps>`
  position: relative;
  padding: 19px;

  .line {
    border: 1px solid #f6f6f6;
    border-radius: 8px;
  }

  button {
    position: absolute;
    top: 20%;
    right: ${({ collapsed }) => (collapsed ? "-15%" : "-5%")};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background: white;
    border: 1px solid #f6f6f6;
    border-radius: 8px;
    cursor: pointer;
  }
`;

export const ChevronIconStyled = styled(Chevron)`
  min-width: 16px;
  min-height: 16px;
`;

export const ChevronHorizontalIcon = styled(
  ChevronIconStyled
)<NavSiderStyledProps>`
  transform: rotate(${({ collapsed }) => (collapsed ? "0deg" : "180deg")});
  transition: transform 0.1s ease-out;
  stroke: #000;
  fill: #000;
`;

export const NavSiderImageBlock = styled.div<NavSiderStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;

  &:hover {
    background: ${({ collapsed }) => (collapsed ? "#F6F6F6" : undefined)};
    border-radius: 8px;
  }

  svg {
    min-width: 20px;
    min-height: 20px;
  }
`;

export const NavSiderButtonItem = styled.button<NavSiderStyledProps>`
  text-wrap: no-wrap;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 100%;
  color: #757575;
  min-width: 40px;
  min-height: 40px;

  &:hover {
    background: ${({ collapsed }) => !collapsed && "#F6F6F6"};
    border-radius: 8px;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;


    &:hover {
      .tooltiptext {
        visibility: visible;
      }
    }

  .info-block {
    display: flex;
    align-items: center;
    position: relative;
    max-width: ${({ collapsed }) => collapsed ? "40px" : "auto"};

    .logout {
      color: #d55f5a;
    }
  }

  p {
    visibility: ${({ collapsed }) => (collapsed ? "hidden" : "visible")};
    transition-delay: ${({ collapsed }) => collapsed ? "0" : "0.2s"};
  }
`;

export const NavSiderSystemBlock = styled.div<NavSiderStyledProps>`
  .image-block {
    svg {
      fill: #757575;
    }
  }

  ul {
    padding: 0px 19px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .link-container {
    display: flex;
    align-items: center;
    text-wrap: no-wrap;
    color: #757575;
    position: relative;
    min-width: 40px;
    min-height: 40px;

    &:hover {
      background: #f6f6f6;
      border-radius: 8px;

      .tooltiptext {
        visibility: visible;
      }
    }

    p {
      visibility: ${({ collapsed }) => (collapsed ? "hidden" : "visible")};
      transition-delay: ${({ collapsed }) => collapsed ? "0" : "0.2s"};
    }
  }
`;

export const NavSiderSystemImageBlock = styled(NavSiderImageBlock)`
  svg {
    fill: #757575;
  }
`;
