import styled from "styled-components";
import {Modal, Select, Tabs} from "antd";
import {IndividualIcon} from "../../../assets/images/svg-elements/individual";
import {GroupIcon} from "../../../assets/images/svg-elements/group";
import {CalendarDayjs} from "../../elements-dayjs/calendar-dayjs";
import {device} from "../../constants";

export const LessonPickerWrapperStyled = styled.div`
    display: flex;
    width: 100%;
    gap: 24px;
    justify-content: center;
    
    .calendar-container {
        max-width: 410px;
    }
    
    @media (${device.tabletMax}) {
        flex-direction: column;
        align-items: center;
        gap: 0;
        
        .calendar-container {
            max-width: initial;
        }
    }
`

export const LessonPickerStyled = styled.div`

    width: 100%;
    border-radius: 16px;
    background-color: #FFFFFF;

    &.padding-outline {
        padding: 24px;
    }

    .header {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .header-controls {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .date-switch-buttons {
                display: flex;
                gap: 8px;

                button {
                    border-radius: 50%;
                    border: none;
                    font-size: 20px;
                    background-color: transparent;
                    transition: .1s;

                    &:hover {
                        cursor: pointer;
                    }

                    &:active {
                        color: #5D51A4;
                    }

                    &:disabled {
                        background-color: transparent;
                        color: #949494;
                    }
                }
            }
        }
    }

    @media (${device.tabletMax}) {
        &.padding-outline {
            padding: 0px;
        }
    }

    @media (${device.mobileMax}) {

        .header {
            .header-controls {
                .view-select {
                    order: 1
                }
            }
        }
    }
`

export const SelectStyled = styled(Select)`
  
`

export const ScheduleTabsStyled = styled(Tabs)`
    
    .ant-tabs-nav-list {
        width: 100%;
    }
    
    .ant-tabs-tab {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        >div {
            color: #5D51A4;
            
            svg {
                stroke: #5D51A4;
            }
        }
    }

    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        background: #5D51A4;
    }
`

export const CalendarStyled = styled(CalendarDayjs)`
    border-radius: 10px;
    padding: 0 12px;
    
    .custom-calendar-header {
        padding: 24px 0 20px 17px;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
    }

    .ant-picker-panel {
        border-radius: 0 0 10px 10px;
        border-top: none;
    }
    
    .ant-picker-content {
        thead tr th {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #BFBFBF;
        }

        tbody tr td {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #595959;

            .ant-picker-cell-inner {
                margin: auto;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &.ant-picker-cell-disabled {
                color: #ece4e4;

                &:before {
                    background-color: transparent;
                }
            }

            .ant-picker-calendar-date-today {
                &.ant-picker-cell-inner:before {
                    border: 1px solid #5D51A4;
                    border-radius: 50%;
                }

                .ant-picker-calendar-date-value {
                    color: #5D51A4;
                }
            }

            &.ant-picker-cell-selected {
                .ant-picker-cell-inner {
                    color: #5D51A4;
                    background: #E8E6F2;
                    border-radius: 50%;
                }
            }

            &.ant-picker-cell:hover {
                .ant-picker-cell-inner {
                    border-radius: 50%;
                    color: #5D51A4;
                }
            }
        }
    }

    @media(${device.tabletMax}) {
        padding: 0;
    }

    @media(${device.mobileMax}) {
        .custom-calendar-header {
            font-size: 20px;
            line-height: 28px;
        }
    }
`

export const ActiveSlotsLimitModalStyled = styled(Modal)`
    .ant-modal-body {
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0 4px 79px 0 rgba(0, 0, 0, 0.10);
        padding: 32px 62px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 8px;
        
        h1 {
            color: #18067D;
            font-size: 20px;
            font-weight: 700;
            margin: 0;
            text-align: center;
            font-style: normal;
            line-height: normal;
        }

        p {
            color: rgba(0, 0, 0, 0.80);
            font-size: 14px;
            font-weight: 400;
        }

        button {
            margin-top: 12px;
            padding-top: 8px;
            display: flex;
            column-gap: 12px;
        }
    }

    @media (${device.mobileMax}) {
        .ant-modal-body {
            padding: 18px 24px;
        }
    }
`

export const IndividualIconStyled = styled(IndividualIcon)`
    stroke: black;
`

export const GroupIconStyled = styled(GroupIcon)`
    stroke: black;
`