import axiosClient from "../../config/axiosConfig";
import {GET_TEACHER_DETAILS, GET_TEACHER_DETAILS_OPEN} from "../endpoints";
import {TTeacherDetailsResponse} from "../../types";
import {getToken} from "../tokenHeaderProvider";

export const getUserDetails = async (languageCode: string, id: string): Promise<TTeacherDetailsResponse> => {

    const isAuthenticated = getToken();

    return axiosClient.get(isAuthenticated ? GET_TEACHER_DETAILS(id) : GET_TEACHER_DETAILS_OPEN(id), {
        headers: {
            "Accept-Language": languageCode,
        }
    });
};
