import axiosClient from "../../config/axiosConfig";

import { PUT_DIRECT_CONNECTION_REQUESTS } from "../endpoints";

import {
    IPutDirectLessonRequest,
  IGetConnectionReqeustsResponse,
} from "../../types";

export const putDirectLessonRequest = async (
  connectionRequestId: string,
  body: IPutDirectLessonRequest
): Promise<IGetConnectionReqeustsResponse> => {
  return axiosClient.put(PUT_DIRECT_CONNECTION_REQUESTS(connectionRequestId), body);
};