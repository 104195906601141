import axiosClient from "../../config/axiosConfig";
import {GET_LESSON_SCHEDULE} from "../endpoints";
import {TLessonScheduleResponse} from "../../types";

export const getLessonSchedule = async (teacherId: string, timeFrom: string, timeTo: string):Promise<TLessonScheduleResponse> => {
  return axiosClient.get(GET_LESSON_SCHEDULE(teacherId),{
    params:{
      timeFrom,
      timeTo
    }
  })
};