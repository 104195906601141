import {FC} from "react";
import {DateIconStyled, PrimaryDateStyled} from "./primary-date.styled";
import dayjs, {Dayjs} from "dayjs";
import {TWeekDay} from "../../../../../types";
import {Translations} from "../../../../../assets/translations";

interface Props {
  daysDataForDisplay: TWeekDay[];
  language: Translations;
}

export const PrimaryDate: FC<Props> = ({daysDataForDisplay, language}) => {

  const startDate = daysDataForDisplay[0] && dayjs(daysDataForDisplay[0].date).locale(language).format("DD MMM");
  const endDate = daysDataForDisplay[daysDataForDisplay.length - 1] && dayjs(daysDataForDisplay[daysDataForDisplay.length - 1].date).locale(language).format("DD MMM");

  return (
    <PrimaryDateStyled>
      <DateIconStyled/>
      <span className="date-container">{startDate}</span>
      {startDate !== endDate && <span className="date-container"> - {endDate}</span>}
    </PrimaryDateStyled>
  )
};