import React, {FC, useMemo} from "react";
import {Skeleton} from "antd";
import {useParams} from "react-router-dom";
import {CloseOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

import {RequestOverviewActiveSlotsStyled, RequestOverviewStyled, VerifiedIconStyled} from "./request-overview.styled";
import {getUsersPicture} from "../../../../../services/user-service";
import {OnlineStatus} from "../../../../elements/online-status";
import {useUserDetails} from "../../../../../hooks/query-hooks";
import {ErrorPage, ErrorPageTypes} from "../../../error-page";
import {TType, useTranslate} from "../../../../../assets/translations";
import {StyledButton} from "../../../../elements/button";
import {getFormattedTimezone} from "../../../../../services/date-service";
import {Hint} from "../../../../elements/hint";
import {Authority} from "../../../../../types";

type ActiveSlot = {
  originalDate: string,
  forDisplayDate: string
}

interface RequestOverviewProps {
  t: TType;
  activeSlots: string[];
  setActiveSlots: React.Dispatch<React.SetStateAction<string[]>>;
  onButtonClick?: () => void;
  showActiveSlots: boolean;
  buttonDisabled?: boolean;
  buttonText: string;
  disabledButtonHintText?: string;
}

interface RequestOverviewActiveSlotsProps {
  t: TType;
  activeSlots: string[];
  setActiveSlots: React.Dispatch<React.SetStateAction<string[]>>;
  role: Authority;
}

export const RequestOverviewActiveSlots: FC<RequestOverviewActiveSlotsProps> = ({t, activeSlots, setActiveSlots, role}) => {

  const {language} = useTranslate();

  const activeSlotsReadyArr: ActiveSlot[] = useMemo(() =>
    activeSlots.map((activeSlot) => (
      {
        originalDate: activeSlot,
        forDisplayDate: dayjs
          .utc(activeSlot, "YYYY-MM-DDTHH:mm:ss")
          .locale(language)
          .local()
          .format(`ddd, MMM, D [${t("at")}] HH:mm`)
      }
    )), [activeSlots]);

  return (
    <RequestOverviewActiveSlotsStyled className={role === Authority.ROLE_TEACHER? "role-teacher" : ""}>
      {role === Authority.ROLE_STUDENT && <h3>{activeSlotsReadyArr.length ? t("connect-request-page.selected-options") : t("connect-request-page.no-selected-options")}</h3>}
      <div className="active-slots-container">
        {activeSlotsReadyArr.map((item) => (
          <div key={item.originalDate} className="active-slot">
            {item.forDisplayDate} <CloseOutlined onClick={() => {
            setActiveSlots(prev => [...prev.filter(slot => slot !== item.originalDate)])
          }}/>
          </div>
        ))}
      </div>
      <h3>GMT {getFormattedTimezone()}</h3>
    </RequestOverviewActiveSlotsStyled>
  )
}

export const RequestOverview: FC<RequestOverviewProps> = ({
                                                            t,
                                                            activeSlots,
                                                            showActiveSlots,
                                                            onButtonClick,
                                                            buttonText,
                                                            setActiveSlots,
                                                            buttonDisabled,
                                                            disabledButtonHintText
                                                          }) => {

  const {teacherId} = useParams();

  const {data: teacher, isLoading: teacherLoading, isError: teacherError} = useUserDetails({
    id: teacherId!,
    enabled: !!teacherId
  });

  if (teacherError) {
    return <ErrorPage t={t} type={ErrorPageTypes.SOMETHING_WRONG}/>
  }

  return (
    <RequestOverviewStyled>
      <div className={`teacher-info ${showActiveSlots ? "border-bottom" : ""}`}>
        {teacherLoading ?
          <Skeleton avatar={{shape: "circle"}} active title={false}
                    paragraph={{rows: 1, width: "100%"}}/>
          :
          <>
            <div className={"image"}>
              <img src={getUsersPicture(teacher.avatarId)} loading="lazy" alt="name"/>
              <OnlineStatus lastActiveTime={teacher.lastActiveTime} size="12px"/>
            </div>
            <div className="info">
              <div className="name">
                <h3>{teacher.firstName} {teacher.lastName}</h3>
                {teacher.verifiedTeacher && <VerifiedIconStyled/>}
              </div>
              <div className="skill">English</div>
            </div>
          </>}
      </div>

      {showActiveSlots && <RequestOverviewActiveSlots t={t} activeSlots={activeSlots} setActiveSlots={setActiveSlots} role={Authority.ROLE_STUDENT}/>}

      <Hint title={buttonDisabled ? disabledButtonHintText : ""}>
        <span style={{width: "100%"}}>
          <StyledButton style={{width: "100%"}} onClick={onButtonClick}
                        disabled={buttonDisabled} className="next-step-button">
            {buttonText}
          </StyledButton>
        </span>
      </Hint>
    </RequestOverviewStyled>
  )
}