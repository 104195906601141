import { useState } from "react";

import { useSearchParams } from "react-router-dom";

import { TType } from "../../../../assets/translations";

import InputSelect from "../../../elements/input-select/input-select";

import {
  GetRequestsLessonStatuses,
  LessonRequestsStatuses,
} from "../../../../types";

interface SortOption {
  name: string;
  value: string;
}

interface FilterOption {
  name: string;
  value: GetRequestsLessonStatuses;
}

const LessonRequestFilters = ({
  t,
  isSort,
  isFilter,
}: {
  t: TType;
  isSort: boolean;
  isFilter: boolean;
}) => {
  const sortOptions: SortOption[] = [
    {
      name: t("lesson-requests.sorts.new-to-old"),
      value: "asc",
    },
    {
      name: t("lesson-requests.sorts.old-to-new"),
      value: "desc",
    },
  ];

  const filterOptions: FilterOption[] = [
    {
      name: t("lesson-requests.filters.approved"),
      value: GetRequestsLessonStatuses.ACCEPTED,
    },
    {
      name: t("lesson-requests.filters.denied"),
      value: GetRequestsLessonStatuses.CANCELLED,
    },
    {
      name: t("lesson-requests.filters.waiting-for-reply"),
      value: GetRequestsLessonStatuses.CREATED,
    },
    {
      name: t("lesson-requests.filters.deactivated"),
      value: GetRequestsLessonStatuses.DEACTIVATED,
    },
  ];

  const [filterProps, setFilterProps] = useState({
    sort: sortOptions[0],
    filter: filterOptions[0],
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSortChange = (item: SortOption) => {
    searchParams.set("sort", item.value);
    setSearchParams(searchParams);
    setFilterProps((prev) => ({ ...prev, sort: item }));
  };
  
  const handleFilterChange = (item: FilterOption) => {
    searchParams.set("filter", item.value);
    setSearchParams(searchParams);
    setFilterProps((prev) => ({ ...prev, filter: item }));
  };
  
  return (
    <div>
      {isSort && (
        <InputSelect
          items={sortOptions}
          enableFilter={false}
          t={t}
          value={filterProps.sort}
          onChange={handleSortChange}
        />
      )}
      {isFilter && (
        <InputSelect
          items={filterOptions}
          enableFilter={false}
          t={t}
          value={filterProps.filter}
          onChange={handleFilterChange}
        />
      )}
    </div>
  );
};

export default LessonRequestFilters;