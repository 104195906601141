import styled from 'styled-components';
import {Typography} from 'antd';

import {colors} from '../theme';
import {device} from "../../components/constants";


const {Text} = Typography;

export const CaptionText = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  color: ${colors.textColorSecondary};
  text-decoration: ${({underline}: { underline?: boolean }) => underline ? 'underline' : 'none'};

  &.ant-typography.ant-typography-secondary {
    color: ${colors.lightGrey};
  }

  svg {
    margin-left: 10px;
  }

  @media (${device.mobileMax}) {
    font-weight: 400;
    letter-spacing: 0.5px;
  }
`;
