import { GetRequestsLessonStatuses, IGetConnectionRequests, LessonRequestType, RequestLesson, TUsersSkill } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../components/redux";
import { getUsersSkills } from "../api/category-api";
import { Translations } from "../assets/translations";
import { useQuery } from "@tanstack/react-query";
import { getConnectionRequests } from "../api/lesson-api/getConnectionRequests";

export interface UseGetConnectionRequestsByTypeProps {
  enabled: boolean;
  status: GetRequestsLessonStatuses | null;
  language: Translations;
  isStudent: boolean;
  type: LessonRequestType | null;
  page?: number;
  queryKey: Array<string | number | boolean>;
}

export const useGetConnectionRequestsByType = ({
  enabled,
  language,
  status,
  isStudent,
  type,
  page = 0,
  queryKey
}: UseGetConnectionRequestsByTypeProps) => {
  const {
    jwtParsed: { user_id },
  } = useSelector((state: RootState) => state.tokensStore);

  const {
    data: userSkills,
    isLoading: isLoadingUserSkills,
    isError: isErrorUserSkills,
  } = useQuery<TUsersSkill[]>({
    queryKey: ["userSkills", user_id, language],
    queryFn: async () => {
      const response = await getUsersSkills(user_id, language);
      return response.data.userCategories;
    },
    enabled: Boolean(user_id),
  });

  const categoryIds = isStudent
    ? null
    : userSkills?.map((skill) => skill.category.id);

  const requestPayload: IGetConnectionRequests = {
    categoryIds,
    type,
    status,
    page
  };

  const {
    data,
    isLoading: isLoadingRequests,
    isError: isErrorRequests,
    refetch
  } = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await getConnectionRequests(requestPayload, language);
      return res.data;
    },
    enabled: !isErrorUserSkills && userSkills !== undefined && enabled,
  });

  return {
    requests: data?.connectionRequests ?? [],
    isLoading: isLoadingUserSkills || isLoadingRequests,
    isError: isErrorUserSkills || isErrorRequests,
    totalItems: data?.totalElements,
    refetch
  };
}