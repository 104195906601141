import { Book } from "../../../assets/images/svg-elements/book";
import { OpenBook } from "../../../assets/images/svg-elements/open-book";
import { BookApple } from "../../../assets/images/svg-elements/book-apple";
import { Blackboard } from "../../../assets/images/svg-elements/blackboard";
import { SettingsIcon } from "../../../assets/images/svg-elements/settings";
import { BookSearch } from "../../../assets/images/svg-elements/book-search";
import { StudyingHat } from "../../../assets/images/svg-elements/studying-hat";
import { TestCheckmarkDone } from "../../../assets/images/svg-elements/test-checkmark-done";

import { ProfileSiderMenuItems } from "../../../types/ProfileSider";
import { TType } from "../../../assets/translations";
import { Authority } from "../../../types";
import { HeartOutlined } from "@ant-design/icons";
import { GlassesIcon } from "../../../assets/images/svg-elements/glasses";

export const getMainNavMenu = (
  t: TType,
  authority: Authority
): ProfileSiderMenuItems[] => [
  {
    id: 0,
    icon: <BookApple />,
    label: t("footer.navigation-menu.home"),
    key: "home",
    to: "/user/profile",
  },
  ...(authority === Authority.ROLE_STUDENT
    ? [
        {
          id: 10000,
          icon: <HeartOutlined />,
          label: t("favourites.header.caption"),
          key: "favorites",
          to: "/favourites",
        },
      ]
    : []),
  ...(authority === Authority.ROLE_TEACHER
    ? [
        {
          id: 1,
          icon: <Blackboard />,
          label: t("schedule.header"),
          key: "schedule",
          to: "/schedule",
        },
        {
          id: 13,
          icon: <StudyingHat />,
          label: t("my-students.title"),
          key: "my-students",
          children: [
            {
              id: 14,
              label: t("my-students.tabs.all-students"),
              key: "my-students",
              to: "/my-students/all-students"
            },
            {
              id: 15,
              label: t("my-students.tabs.invited-students"),
              key: "my-students",
              to: "/my-students/invited-students"
            }
          ]
        },
      ]
    : []),
  {
    id: 2,
    icon: <Book />,
    label: t("lessons.header.title"),
    key: "lessons",
    to: "/lessons",
  },
  {
    id: 17,
    icon: <GlassesIcon />,
    label: t("lesson-requests.title"),
    key: "lesson-requests",
    to: "/lesson-requests",
  },
];

  // TEMPLATE WITH CHILDREN
  
  // {
  //     id: 7,
  //     icon: <OpenBook/>,
  //     label: "Шаблони курсів",
  //     key: "templates-courses",
  //     children: [
  //         {
  //             id: 8,
  //             label: "Шаблони курсів",
  //             key: "templates-courses",
  //             to: "#"
  //         },
  //         {
  //             id: 9,
  //             label: "Шаблони курсів",
  //             key: "templates-courses",
  //             to: "#"
  //         },
  //         {
  //             id: 100,
  //             label: "Шаблони курсів",
  //             key: "templates-courses",
  //             to: "#"
  //         },
  //     ]
  // },

export const getOtherNavMenu = (t: TType): ProfileSiderMenuItems[] => [
  {
    id: 0,
    icon: <SettingsIcon className="settings-icon" />,
    label: t("settings"),
    key: "settings",
    to: "/user/settings",
  },
];


