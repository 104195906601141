import {SlotsBoardStyled} from "./slots-board.styled";
import {FC, useRef} from "react";
import {DayCard} from "../day-card/day-card";
import {EViews} from "../../lesson-picker";
import {TWeekDay} from "../../../../../types";
import {Dayjs} from "dayjs";

interface Props {
  daysDataForDisplay: TWeekDay[];
  activeSlots?: string[];
  setActiveSlots?: React.Dispatch<React.SetStateAction<string[]>>
  view: EViews;
  selectedDate: Dayjs;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
  setActiveSlotsLimitModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  goToConnectRequestPage?: boolean;
  teacherId?: string;
  roleTeacher: boolean;
}

export const SlotsBoard: FC<Props> = ({
                                        daysDataForDisplay,
                                        activeSlots,
                                        setActiveSlots,
                                        selectedDate,
                                        onSwipeLeft,
                                        onSwipeRight,
                                        setActiveSlotsLimitModalOpen,
                                        goToConnectRequestPage,
                                        teacherId,
                                        roleTeacher
                                      }) => {

  const touchStart = useRef({x: 0, y: 0});

  function handleTouchStart(e: React.TouchEvent) {
    touchStart.current.x = e.touches[0].pageX;
    touchStart.current.y = e.touches[0].pageY;
  }

  function handleTouchEnd(e: React.TouchEvent) {
    const touchEndX = e.changedTouches[0].pageX;
    const touchEndY = e.changedTouches[0].pageY;

    const diffY = Math.abs(touchStart.current.y - touchEndY)

    if (diffY > 50) {
      return
    }

    const diffX = touchStart.current.x - touchEndX;

    if (diffX > 30) {
      onSwipeLeft && onSwipeLeft();
    } else if (diffX < -30) {
      onSwipeRight && onSwipeRight()
    }
  }

  return (
    <SlotsBoardStyled onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      {daysDataForDisplay && daysDataForDisplay.map((day) => (
        <DayCard activeSlots={activeSlots} teacherId={teacherId} goToConnectRequestPage={goToConnectRequestPage}
                 setActiveSlotsLimitModalOpen={setActiveSlotsLimitModalOpen} setActiveSlots={setActiveSlots}
                 key={day.dayName} dayData={day} roleTeacher={roleTeacher}
                 selectedDate={selectedDate}/>))}
    </SlotsBoardStyled>
  )
};
