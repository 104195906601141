import styled from 'styled-components';
import {Typography} from 'antd';
import {colors} from '../theme';

const {Text} = Typography;


export const InputTitleText = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: ${colors.primaryColor};
  text-decoration: ${({underline}: { underline?: boolean }) => underline ? 'underline' : 'none'};

  &.ant-typography.ant-typography-secondary {
    color: ${colors.lightGrey};
  }

  svg {
    margin-left: 10px;
  }
`;
