import styled from 'styled-components';


export const Online = styled.div`
  position: absolute;
  bottom: 0;
  right: 5px;
  height: ${({size}: { size: string }) => size};
  width: ${({size}: { size: string }) => size};
  border-radius: 50%;
  background: #41D37E;
  border: 1px solid #FFFFFF;
`;

export const Offline = styled.div`
  position: absolute;
  bottom: 0;
  right: 5px;
  height: ${({size}: { size: string }) => size};
  width: ${({size}: { size: string }) => size};
  border-radius: 50%;
  background-color: #FFFFFF;
  border: 1px solid rgb(140, 140, 140);
`;