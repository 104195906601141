import {NotAvailableSlotStyled} from "./not-available-slot.styled";
import {FC} from "react";
import {GlassesIcon} from "../../../../../assets/images/svg-elements/glasses";
import {TType} from "../../../../../assets/translations";

interface Props {
  t: TType
}

export const NotAvailableSlot: FC<Props> = ({t}) => {

  return (
    <NotAvailableSlotStyled>
        <div className="status">
          {t("user-details.slot-types.not-available.title")}
        </div>
        <div className="content">
          <GlassesIcon/>
          {t("user-details.slot-types.not-available.description")}
        </div>
    </NotAvailableSlotStyled>
  )
};