import styled from "styled-components";

export const SlotStyled = styled.div`
    
    border-radius: 8px;
    
        .slot {
          padding: 8px;
          background-color: #F5F5F5;
          border: 1px solid #D9D9D9;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
    }
    
    .slot.type-available {
        &:hover {
            cursor: pointer;
        }

        &.active {
            border: 1px solid #5D51A4;
            background-color: #E8E6F2;
            font-weight: 500;
        }
    }
    
    .slot.type-individual-lesson {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 4px;
        border: none;
        background-color: #E8E6F2;
        
        .description {
            display: flex;
            font-weight: 600;
            line-height: 16px;
            color: rgb(140, 140, 140);
            
            svg {
                height: 14px;
                width: 14px;
                stroke: #595959D9;
            }
        }
        
        &:hover {
            cursor: not-allowed;
        }
    }
    
    .slot.type-not-available {
        display: flex;
        flex-direction: column;
        background-color: #E8E6F2;
        padding: 8px;
        border-radius: 8px;
        flex-grow: 1;

        .status {
            display: flex;
            justify-content: space-between;
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: auto;
            text-align: center;
            row-gap: 4px;

            svg {
                height: 24px;
                width: 24px;
                color: black;
                stroke: black;
            }
        }
    }
`