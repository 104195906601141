import styled from "styled-components";
import {device} from "../../../../constants";

export const UpcomingSlotsBannerStyled = styled.div`
    border-radius: 8px;
    min-height: 54px;
    
    .content-container {
        padding: 7px 16px;
        display: flex;
        gap: 16px;
        background-color: #DCEAFF;
        border: 1px solid #003E99;
        min-height: 54px;
        border-radius: 8px;
        
        &.upcoming-slots-unavailable {
            background: rgba(255,0,0,0.3);
            color: #8d0202;
            border: 1px solid #8d0202;
            
            .banner-info {
                color: #8d0202;
            }
        }
        
      .banner-info {
          display: flex;
          gap: 9px;
          font-size: 12px;
          align-items: center;
          color: #003E99;
          user-select: none;
      }
        
      .upcoming-slots-container {
          display: flex;
          gap: 8px;
      }
    }
    
    .ant-skeleton {
        
        .ant-skeleton-content {
            ul {
                margin: 0;
                li {
                    height: 54px;
                    border-radius: 8px;
                }
            }
        }
    }
    
    @media (${device.mobileMax}) {

        .content-container {
             padding: 7px 12px;
            flex-direction: column;
            justify-content: center;
        }
        
    }
`