import { HomePage } from './../components/pages/home-page/home-page';
import { ReactElement } from "react";

export interface ProfileSiderMenuItems {
    id: number;
    icon: ReactElement<any, any>;
    label: string;
    key: string;
    children?: ProfileSiderMenuItemsChildren[];
    to?: string;
}

export interface ProfileSiderMenuItemsChildren {
    id: number;
    label: string;
    key: string;
    to: string;
}

export enum SiderPathWithoutSider {
    DRAWBOARD = "/drawboards",
    MEETING = "/meeting",
    LOGOUT = "/logout",
}