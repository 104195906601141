import * as React from "react";
import {CSSProperties, FC, MouseEvent, ReactElement} from "react";
import {LoadingOutlined} from "@ant-design/icons";

import {StyledButtonWrapper} from "./button.styled";
import {Hint} from "../hint";


export enum ButtonTheme {
		BLACK = 'black',
		WHITE = 'white',
		BLUE = "blue",
		DARK_BLUE = "dark-blue",
		INVERT_BLUE = "invert-blue",
}

interface Props {
		children: string | ReactElement;
		theme?: ButtonTheme;
		onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
		style?: CSSProperties;
		disabled?: boolean;
		className?: string;
		loading?: boolean;
		dataAttr?: string;
		hintForDisabledButton?: React.ReactNode;
		type?: "submit" | "button" | "reset";
}

export const StyledButton: FC<Props> = ({
																						children,
																						theme = ButtonTheme.BLACK,
																						onClick = () => {
																						},
																						style = {},
																						disabled = false,
																						className = '',
																						loading = false,
																						dataAttr,
																						hintForDisabledButton,
																						type = "button"
																				}) => {

	function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
		if (disabled) return

		onClick && onClick(e);
	}

	return (
		<Hint showHint={disabled} title={hintForDisabledButton}>
			<StyledButtonWrapper type={type} className={`${theme.concat(` ${className}`)} ${disabled ? "disabled" : ""}`}
													 onClick={handleClick}
													 style={style}
													 data-cy={dataAttr}>
				{loading && <LoadingOutlined/>}
				{children}
			</StyledButtonWrapper>
		</Hint>
	)
};