import { TType } from "../../../../assets/translations";
import {
  GetRequestsLessonStatuses,
  LessonRequestsStatuses,
} from "../../../../types";
import {
  ApproveStatusBadge,
  DeniedStatusBadge,
  WaitingForReplyStatusBadge,
} from "./lesson-request-status-styled";

interface LessonRequestStatusProps {
  status: GetRequestsLessonStatuses;
  t: TType;
}

const LessonRequestStatus = ({ status, t }: LessonRequestStatusProps) => {
  switch (status) {
    case GetRequestsLessonStatuses.ACCEPTED:
      return (
        <ApproveStatusBadge>
          {t(`lesson-requests.statuses.${LessonRequestsStatuses.ACCEPTED}`)}
        </ApproveStatusBadge>
      );
    case GetRequestsLessonStatuses.CREATED:
      return (
        <WaitingForReplyStatusBadge>
          {t(`lesson-requests.statuses.${LessonRequestsStatuses.CREATED}`)}
        </WaitingForReplyStatusBadge>
      );
    case GetRequestsLessonStatuses.CANCELLED:
      return (
        <DeniedStatusBadge>
          {t(`lesson-requests.statuses.${LessonRequestsStatuses.CANCELLED}`)}
        </DeniedStatusBadge>
      );
    case GetRequestsLessonStatuses.DEACTIVATED:
      return (
        <DeniedStatusBadge>
          {t(`lesson-requests.statuses.${LessonRequestsStatuses.DEACTIVATED}`)}
        </DeniedStatusBadge>
      );
    default:
      return <></>;
  }
};

export default LessonRequestStatus;
