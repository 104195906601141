import { useRef, useState } from "react";

interface ReturnUSeVisibleWithRect<T> {
    rect: DOMRect | null;
    isVisible: boolean;
    showElement: () => void;
    hideElement: () => void;
    ref: React.MutableRefObject<T | null>
}

export function useVisibleWithRect<T extends HTMLElement = HTMLElement>(): ReturnUSeVisibleWithRect<T> {
    const [rect, setRect] = useState<DOMRect | null>(null);
    const [isVisible, setIsVisible] = useState(false);
  
    const ref = useRef<T | null>(null);
  
    const showElement = () => {
      if (ref.current) {
        const boundingRect = ref.current.getBoundingClientRect();
        setRect(boundingRect);
        setIsVisible(true);
      }
    };

    const hideElement = () => {
        setIsVisible(false)
    }
  
    return {
      rect,
      isVisible,
      showElement,
      hideElement,
      ref
    };
  }
