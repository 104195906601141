import styled from "styled-components";

export const DayCardStyled = styled.div`
  
    width: 100%;
    min-width: 85px;
    display: flex;
    flex-direction: column;
    
  .date-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 6px 8px;
      border-right: 1px solid #D9D9D9;
      border-bottom: 1px solid #D9D9D9;
      position: relative;
      background-color: #FAFAFA;
      
      .day-name {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-align: center;
            color: #8C8C8C;
      }
      
      .day-number {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
      }
      
      &.today {

          .day-number {
              font-weight: 700;
              background-color: #E8E6F2;
              color: #5D51A4;
          }
      }
      
      &.selected-date {
          
          .day-name {
              color: #5D51A4;
          }

          .day-number {
              color: #5D51A4
          }
      }
      
      &.selected-date:after {
          content: "";
          position: absolute;
          border-bottom: 2px solid #5D51A4;
          background-color: red;
          width: 100%;
          bottom: -2.5px;
      }
  }
    
    .slots-container {
        min-height: 340px;
        padding: 12px 4px;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        flex-grow: 1;
    }
  
`