import { useState } from "react";

import { InputProps } from "../input/input";

import {
  TextAreaContainer,
  TextAreaLabel,
  TextAreaStyled,
  TextAreaWrapper,
} from "./textarea-styled";

interface InputTextAreaProps extends Omit<InputProps, "type"> {
  maxLength?: number;
}

const TextArea = ({
  onChange,
  placeholder,
  disabled,
  label,
  required,
  maxLength = 100,
  defaultValue,
}: InputTextAreaProps) => {
  const [value, setValue] = useState<string>(defaultValue || "");

  const handleChange = (newValue: string) => {
    if (!onChange) return;
    
    if (newValue.length > maxLength) return;
    onChange(newValue);
    setValue(newValue);

  };

  return (
    <TextAreaWrapper>
      {label && <TextAreaLabel>{label}</TextAreaLabel>}
      <TextAreaContainer>
        <TextAreaStyled
          onChange={(e) => handleChange(e.target.value)}
          value={value}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
        />
        <p>
          {value.length}/{maxLength}
        </p>
      </TextAreaContainer>
    </TextAreaWrapper>
  );
};

export default TextArea;
