import {FC} from "react";
import {DayCardStyled} from "./day-card.styled";
import {Slot} from "../slot/slot";
import dayjs, {Dayjs} from "dayjs";
import {NotAvailableSlot} from "../not-available-slot/not-available-slot";
import {TWeekDay} from "../../../../../types";
import classNames from "classnames";
import {useTranslate} from "../../../../../assets/translations";

interface Props {
  dayData: TWeekDay;
  activeSlots?: string[];
  setActiveSlots?: React.Dispatch<React.SetStateAction<string[]>>
  selectedDate: Dayjs;
  setActiveSlotsLimitModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  goToConnectRequestPage?: boolean;
  teacherId?: string;
  roleTeacher: boolean;
}

export const DayCard: FC<Props> = ({
                                     dayData: {dayName, dayNumber, slots, date},
                                     setActiveSlotsLimitModalOpen,
                                     activeSlots,
                                     setActiveSlots,
                                     selectedDate,
                                      goToConnectRequestPage,
                                      teacherId,
                                      roleTeacher
                                   }) => {

  const isToday = dayjs().isSame(date, "day");
  const isSelectedDate = dayjs(selectedDate).isSame(date, "day");

  const {t} =useTranslate();

  return (
    <DayCardStyled>
      <div className={classNames("date-container",{
        today: isToday,
        "selected-date": isSelectedDate
      })}>
        <span className="day-name">{dayName}</span>
        <span className="day-number">{dayNumber}</span>
      </div>
      <div className="slots-container">
        {slots.length ?
          slots.map((slot, index)=><Slot goToConnectRequestPage={goToConnectRequestPage} roleTeacher={roleTeacher} teacherId={teacherId} t={t} setActiveSlotsLimitModalOpen={setActiveSlotsLimitModalOpen} activeSlots={activeSlots} setActiveSlots={setActiveSlots} key={index} slotData={slot}/>)
        :
          <NotAvailableSlot t={t}/>
        }
      </div>
    </DayCardStyled>
  )
};