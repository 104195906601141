import { memo } from 'react';

import { TType } from '../../../assets/translations';

import { MenuButtonStyled, MenuWrapperStyled } from './menu-styled';

import { FAQMenu } from '../../../types/FAQ';
import { IconColors } from '../../../types/Icons';

interface MenuProps<T> {
  items: FAQMenu<T>[];
  handleClick: (key: keyof T) => void;
  selectedKey: keyof T;
  t: TType;
  icon?: boolean;
  cn?: string;
}

const MenuComponent = <T,>({
  items,
  handleClick,
  selectedKey,
  icon,
  cn
}: MenuProps<T>) => {
  return (
    <MenuWrapperStyled className={cn}>
      {items.map(item => {
        const Icon = item.icon;
        const isActive = selectedKey === item.key;

        return (
          <MenuButtonStyled
            isActive={isActive}
            className="white"
            key={item.id}
            onClick={() => handleClick(item.key)}
          >
            {icon && Icon && (
              <Icon stroke={isActive ? IconColors.Active : IconColors.Default} />
            )}
            <span>{item.label}</span>
          </MenuButtonStyled>
        );
      })}
    </MenuWrapperStyled>
  );
};

const Menu = memo(MenuComponent) as typeof MenuComponent;

export default Menu;
