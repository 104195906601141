import styled from "styled-components";

export const PaginationButtonStyled = styled.button`
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 1px solid #D9D9D9;
    padding: 1px 7px;
    cursor: pointer;

    &.active {
        background: #18067D;
        border: 1px solid #18067D;
        color: #FFFFFF;
    }

    &:disabled {
        color: #D9D9D9;
    }

`