import { AxiosResponse } from "axios";

import axiosClient from "../../config/axiosConfig";

import { POST_USER_APPLICATIONS } from "../endpoints";

interface PostUserApplicationsBody {
    message: string;
    price: number;
}

export const postUserApplications = async (connectionRequestId: string, body: PostUserApplicationsBody): Promise<AxiosResponse> => {
    return axiosClient.post(POST_USER_APPLICATIONS(connectionRequestId), body, {
        headers: {
            "Content-type": "application/json",
        },
    });
};
