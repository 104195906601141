import styled from "styled-components";
import {
  InputSelectLabel,
  InputSelectWrapper,
} from "../input-select/input-select-styled";

export const TextAreaWrapper = styled(InputSelectWrapper)``;

export const TextAreaLabel = styled(InputSelectLabel)``;

export const TextAreaStyled = styled.textarea`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  text-align: left;

  ::-webkit-resizer {
    display: block;
  }

  &:focus {
    outline: none;
  }
`;

export const TextAreaContainer = styled.div`
  p {
    width: 50px;
    float: right;
    margin-top: -8px;
    color: rgba(0, 0, 0, 0.25);
  }
`;
