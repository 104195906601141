import { FC, useState } from "react";

import PaginationButton from "./pagination-button/pagination-button";

import { PaginationWrapper } from "./pagination-styled";

interface PaginationProps {
  totalPages: number;
  paginationRange: (string | number)[];
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  currentPage: number;
  goToPage: (value: number | string) => void;
}

const Pagination: FC<PaginationProps> = ({
  totalPages,
  currentPage,
  goToNextPage,
  goToPage,
  goToPreviousPage,
  paginationRange,
}) => {
  return (
    <PaginationWrapper>
      <PaginationButton
        handleClick={goToPreviousPage}
        disabled={currentPage <= 1}
      >
        {"<"}
      </PaginationButton>
      {paginationRange.map((page, index) => {
        if (typeof page === "number") {
          return (
            <PaginationButton
              key={page}
              isActive={page === currentPage}
              handleClick={() => goToPage(page)}
            >
              {page}
            </PaginationButton>
          );
        }
        return (
          <span className="ellipsis" key={`esipsis-${index}`}>
            {page}
          </span>
        );
      })}
      <PaginationButton
        handleClick={goToNextPage}
        disabled={currentPage === totalPages}
      >
        {">"}
      </PaginationButton>
    </PaginationWrapper>
  );
};

export default Pagination;
