import styled from "styled-components";

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;

  .ellipsis {
    color: #00000040;
    font-weight: 400;
  }
`;
