import {useContext} from "react";
import {useQuery} from "@tanstack/react-query";

import {QUERY_KEYS} from "../../config";
import {getUserDetails} from "../../api/user-api";
import {TranslationsContext} from "../../assets/translations/translationsContext";


interface Props {
    id: string;
    enabled: boolean;
}

export const useUserDetails = ({id, enabled}: Props) => {

    const context = useContext(TranslationsContext);

    return useQuery({
        queryKey: QUERY_KEYS.TEACHER_DETAILS.detailsById(id),
        queryFn: () => getUserDetails(context.language, id!).then(({data}) => data),
        enabled,
        retry: 0
    });
}