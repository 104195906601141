import styled from "styled-components";

const StatusBadgeGeneral = styled.div`
    width: fit-content;
    min-width: 140px;
    height: 28px;
    border-radius: 100px;
    padding: 4px 12px;
    text-align: center;
`

export const ApproveStatusBadge = styled(StatusBadgeGeneral)`
    background: #B0E8E4;
`

export const WaitingForReplyStatusBadge = styled(StatusBadgeGeneral)`
    background: #F7C8A1;
`

export const DeniedStatusBadge = styled(StatusBadgeGeneral)`
    background: #F5A3BC;
`
