import React, {FC} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {StepOneStyled} from "./step-one.styled";
import {LessonPicker} from "../../../../elements/lesson-picker";
import {TType} from "../../../../../assets/translations";
import {RequestOverview} from "../../components/request-overview";
import {useLocalStorage} from "../../../../../hooks/useLocalStorage";
import {activeSlotsKey} from "../../../../../services/storage-service";

interface Props {
  t: TType;
}

export const StepOne: FC<Props> = ({t}) => {

  const [activeSlots, setActiveSlots] = useLocalStorage<string[]>(activeSlotsKey, []);

  const {teacherId} = useParams();
  const navigate = useNavigate();

  function handleButtonClick() {
    navigate("step-two");
  }

  return (
    <StepOneStyled>
      <LessonPicker t={t} teacherId={teacherId} paddingOutline activeSlots={activeSlots}
                    setActiveSlots={setActiveSlots}/>
      <RequestOverview t={t} buttonText={t("connect-request-page.next")}
                       disabledButtonHintText={t("connect-request-page.disabled-next-hint")}
                       buttonDisabled={!activeSlots.length} showActiveSlots activeSlots={activeSlots}
                       setActiveSlots={setActiveSlots} onButtonClick={handleButtonClick}/>
    </StepOneStyled>
  )
};