import {FC, ReactElement, useEffect, useMemo, useState} from "react";

import {useTranslate} from "../../../assets/translations";
import {InputTitleText} from "../../../ui-elements/text";
import {CaptionTextStyled, FormTitleWrapper, InputComponentWrapper, InputStyled} from "./form-input.styled";


interface Props {
    placeholder: string;
    title: string | ReactElement;
    value?: string;
    name?: string;
    optional?: boolean;
    setValue: (value: any) => void;
    errorMessage?: string;
    validate?: (value: any) => void;
    id?: string;
    disabled?: boolean;
    inputDataAttr?: string;
    errorDataAttr?: string;
    wrapperDataAttr?: string;
    maxLength?: number;
    wrapperClassName?: string
    type?: React.HTMLInputTypeAttribute;
}


export const FormInput: FC<Props> = ({
                                         errorMessage,
                                         title,
                                         placeholder,
                                         value,
                                         setValue,
                                         validate,
                                         optional,
                                         name,
                                         id,
                                         disabled,
                                         inputDataAttr,
                                         errorDataAttr,
                                         wrapperDataAttr,
                                         maxLength = 50,
                                        wrapperClassName,
                                        type,
                                     }) => {

    const {t} = useTranslate();

    const [errMessage, setErrMessage] = useState<string | undefined>(errorMessage);

    const errorOccurred = useMemo(() => errMessage && errMessage.length > 0, [errMessage]);

    useEffect(() => {
        if (value?.length === maxLength) {
            setErrMessage(t("profile.settings.account.max-length-warn", {maxLength}));
        } else if (errorMessage) {
            setErrMessage(errorMessage)
        } else {
            setErrMessage(undefined);
        }
    }, [errorMessage, value, maxLength]);


    return <InputComponentWrapper className={wrapperClassName}>
        <FormTitleWrapper>
            <InputTitleText>{title}</InputTitleText>
            {optional && <CaptionTextStyled> (Optional)</CaptionTextStyled>}
        </FormTitleWrapper>
        <div className="input-wrapper" data-cy={wrapperDataAttr}>
            <InputStyled id={id}
                         type={type}
                         maxLength={maxLength}
                         placeholder={placeholder}
                         onChange={setValue}
                         allowClear
                         name={name}
                         status={errorOccurred ? "error" : ""}
                         onBlur={validate}
                         value={value}
                         data-cy={inputDataAttr}
                         disabled={disabled}
            />

            {errorOccurred &&
                <div className="input-error-message" data-cy={errorDataAttr}>
                    {errMessage}
                </div>
            }
        </div>
    </InputComponentWrapper>
};
