import styled from "styled-components";

import { StyledButton } from "../../../elements/button";

export const ActionButtons = styled(StyledButton)`
    padding: 8px 15px;
    font-size: 14px;
    line-height: 22px;

    &:has(svg) {
        padding: 7px 9px;
    }
`