import { useLocation } from "react-router-dom";

import { SiderPathWithoutSider } from "../types/ProfileSider";
import { loadFromLs, tokenKey } from "../services/storage-service";
import { useMediaQuery } from "react-responsive";
import { device } from "../components/constants";

export const useRenderSider = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isDesktop = useMediaQuery({
    query: `(${device.desktop})`,
  });

  const pathsWithoutProfileSider = [
    SiderPathWithoutSider.DRAWBOARD,
    SiderPathWithoutSider.MEETING,
    SiderPathWithoutSider.LOGOUT,
  ];

  const isAuthenticated = !!loadFromLs(tokenKey);

  const noSider = pathsWithoutProfileSider.some((path) => {
    if (path === "/meeting") {
      return location.pathname === "/meeting" && searchParams.has("topic");
    }

    return location.pathname.startsWith(path);
  });

  const isHomepage = location.pathname === "/" || location.pathname === "/search";

  const noSiderHomepage = isHomepage && !isAuthenticated;

  const shouldRenderProfileSider = !(noSider || noSiderHomepage) && isAuthenticated && isDesktop;

  return { shouldRenderProfileSider };
};
