import { FC } from "react";

import { StudentDropdownWrapper } from "./student-dropdown-styled";

import StudentDropdownCard from "./student-dropdown-card/student-dropdown-card";

import { RequestLesson } from "../../../../../types";
import { TType } from "../../../../../assets/translations";

interface StudentDropdownProps {
  t: TType;
  card: RequestLesson;
}

const StudentDropdown: FC<StudentDropdownProps> = ({ t, card }) => {
  const { applicants, id, studentId } = card;
  return (
    <StudentDropdownWrapper>
      {applicants.map((applicant) => (
        <StudentDropdownCard
          key={applicant.id}
          teacher={applicant}
          connectionRequestId={id}
          studentId={studentId}
          t={t}
        />
      ))}
    </StudentDropdownWrapper>
  );
};

export default StudentDropdown;
