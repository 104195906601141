import {FC} from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";

import {StyledButton} from "../../elements/button";
import {Translations, TType, useTranslate} from "../../../assets/translations";
import {PageWrapper} from "./error-page.styled";
import pluralize from "pluralize";
import {NotFoundIcon} from "../../../assets/images/svg-elements/not-found-icon";
import {AccessDeniedIcon} from "../../../assets/images/svg-elements/access-denied-icon";
import {pluralizeUk} from "../../../services/user-service";


export enum ErrorPageTypes {
    NOT_FOUND = "NOT_FOUND",
    ACCESS_DENIED = "ACCESS_DENIED",
    SERVER_MAINTENANCE = "SERVER_MAINTENANCE",
    SOMETHING_WRONG = "SOMETHING_WRONG"
}

interface Props {
    t: TType;
    type: ErrorPageTypes;
    isTeacher: boolean;
}

export const ErrorPageComponent: FC<Props> = ({t, type, isTeacher}) => {

    const navigate = useNavigate();
    const location = useLocation();

    const {language} = useTranslate();

    const isUkrainian = language === Translations.uk;

    const handleNavigation = () => {
        const navigationPath = isTeacher ? "/user/profile" : "/";
        const isHomepage = location.pathname === "/";

        if (isHomepage) {
            window.location.reload();
            return null;
        }

        return navigationPath   
    }

    const handleClick = () => {
        const path = handleNavigation();
        if (path) {
            navigate(path);
        }
    }

    switch (type) {

        case ErrorPageTypes.NOT_FOUND:
            return <PageWrapper>
                <NotFoundIcon/>
                <div className="text">
                    <h2>{t("errors.not-found.title")}</h2>
                    <p>{t("errors.not-found.caption")}</p>
                </div>
                <StyledButton onClick={() => handleClick()}>{t("back.home.caption")}</StyledButton>
            </PageWrapper>

        case ErrorPageTypes.ACCESS_DENIED:
            return <PageWrapper>
                <AccessDeniedIcon/>
                <div className="text">
                    <h2>{t("errors.access-denied.title")}</h2>
                    <p>{t("errors.access-denied.caption")}</p>
                </div>
                <StyledButton onClick={() => handleClick()}>{t("back.home.caption")}</StyledButton>
            </PageWrapper>

        case ErrorPageTypes.SERVER_MAINTENANCE:
            return <PageWrapper>
                <div className="text">
                    <h2>{t("errors.maintenance.title")}</h2>
                    <p>{t("errors.maintenance.caption")} <b>{!isUkrainian ? pluralize(t("day"), 1, true) :
                        pluralizeUk(["день", "дні", "днів"], 1)}</b>
                    </p>
                </div>
                <StyledButton onClick={() => handleClick()}>{t("back.home.caption")}</StyledButton>
                </PageWrapper>

        case ErrorPageTypes.SOMETHING_WRONG:
            return <PageWrapper>
                <NotFoundIcon/>
                <div className="text">
                    <h2>{t("errors.something-wrong.title")}</h2>
                    <p>{t("errors.something-wrong.caption")}</p>
                </div>
                <StyledButton onClick={() => handleClick()}>{t("back.home.caption")}</StyledButton>
            </PageWrapper>
        default:
            const fallbackPath = handleNavigation(); 
            return fallbackPath ? <Navigate to={fallbackPath} /> : null;
    }

};
