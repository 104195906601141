import {FC} from "react";
import {SlotStyled} from "./slot.styled";
import {EScheduleSlotType, TExtendedScheduleSlot} from "../../../../../types";
import classNames from "classnames";
import {TType} from "../../../../../assets/translations";
import {useNavigate} from "react-router-dom";
import {activeSlotsKey, saveToLs} from "../../../../../services/storage-service";

interface Props {
  t: TType
  slotData: TExtendedScheduleSlot
  activeSlots?: string[];
  setActiveSlots?: React.Dispatch<React.SetStateAction<string[]>>;
  setActiveSlotsLimitModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  goToConnectRequestPage?: boolean;
  teacherId?: string;
  roleTeacher: boolean
}

export const Slot: FC<Props> = ({t, slotData, teacherId, roleTeacher, setActiveSlotsLimitModalOpen, goToConnectRequestPage, activeSlots, setActiveSlots}) => {

  const navigate = useNavigate();

  function handleAvailableClick() {

    if (goToConnectRequestPage) {
      saveToLs(activeSlotsKey, [slotData.startDate])
      navigate(`/connect-request/${teacherId}`);
      return;
    }

    activeSlots && setActiveSlots && setActiveSlots((prev) => {

      const slotAlreadyActive = prev.some(item => item === slotData.startDate);

      if (slotAlreadyActive) {
        return [...prev.filter((item) => item !== slotData.startDate)];
      } else {

        if (activeSlots.length >= 5) {
          setActiveSlotsLimitModalOpen(true);
          return prev;
        }

        if (roleTeacher) {
          return [slotData.startDate]
        }

        return [...prev, slotData.startDate];
      }
    });
  }

  let slotType = null;

  if (slotData.type === EScheduleSlotType.INDIVIDUAL_LESSON) {
    slotType = (
      <div className="slot type-individual-lesson">
        <div className="description">
          {t("user-details.slot-types.individual-lesson")}
        </div>
        <div>
          {slotData.startDateDisplayFormat}
        </div>
      </div>
    )
  }else if (slotData.type === EScheduleSlotType.AVAILABLE) {
    slotType = (
      <div className={classNames( "slot", "type-available", {
        "active": activeSlots && activeSlots.some((item) => item === slotData.startDate),
      })}
           onClick={handleAvailableClick}
      >
        {slotData.startDateDisplayFormat}
      </div>
    )
  }

  return (
    <SlotStyled>
      {slotType}
    </SlotStyled>
  );
};