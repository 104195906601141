import {FC, useEffect, useState} from "react";
import {ConfigProvider} from "antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

import {
  CalendarStyled,
  LessonPickerStyled, LessonPickerWrapperStyled,
  ScheduleTabsStyled,
  SelectStyled, ActiveSlotsLimitModalStyled
} from "./lesson-picker.styled";
import {PrimaryDate, UpcomingSlotsBanner} from "./ui-elements";
import {SlotsBoard} from "./ui-elements/slots-bord/slots-board";
import {getLessonSchedule} from "../../../api/user-api/getLessonSchedule";
import {getHourWithTzOffsetDayjs} from "../../../services/date-service";
import {TScheduleSlot, TUpcomingSlot, TWeekDay} from "../../../types";
import {useMediaQuery} from "react-responsive";
import {device} from "../../constants";
import {Translations, TType, useTranslate} from "../../../assets/translations";
import {StyledButton} from "../button";
import {capitalizeFirstLetter} from "../../../services/teacher-service";
import {StyledDivider} from "../../pages/my-schedule-page/my-schedule-page.styled";

import isoWeek from "dayjs/plugin/isoWeek";
import dayjs, {Dayjs} from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/uk";

dayjs.extend(isoWeek);

export enum EViews {
  DAY = "day",
  WEEK = "week",
}

export type TDaysDisplayRange = {
  rangeStart: number;
  rangeEnd: number;
}

const timeFormat = "YYYY-MM-DDTHH:mm:ss";

const calendarDateRange: [dayjs.Dayjs, dayjs.Dayjs] = [dayjs(), dayjs().add(27, "day")];

// DAYS DISPLAY RANGE VARIABLES ====================================
const WEEK_VIEW_RATE = 7;
const WEEK_VIEW_RATE_REDUCED = 3;
const DAY_VIEW_RATE = 1;

const FIRST_DAY_INDEX = 0;
const LAST_DAY_INDEX = 28;

const WEEK_VIEW_INITIAL_SEGMENT_RANGE = {rangeStart: 0, rangeEnd: 7};
const WEEK_VIEW_INITIAL_SEGMENT_RANGE_REDUCED = {rangeStart: 0, rangeEnd: 3};
const DAY_VIEW_INITIAL_SEGMENT_RANGE = {rangeStart: 0, rangeEnd: 1};

const WEEK_VIEW_FINAL_SEGMENT_RANGE = {rangeStart: 21, rangeEnd: 28};
const WEEK_VIEW_FINAL_SEGMENT_RANGE_REDUCED = {rangeStart: 25, rangeEnd: 28};
const DAY_VIEW_FINAL_SEGMENT_RANGE = {rangeStart: 27, rangeEnd: 28};


interface Props {
  teacherId?: string;
  showCalendar?: boolean;
  t: TType;
  paddingOutline?: boolean;
  goToConnectRequestPage?: boolean;
  activeSlots?: string[];
  setActiveSlots?: React.Dispatch<React.SetStateAction<string[]>>;
  roleTeacher?: boolean;
}

export const LessonPicker: FC<Props> = ({
                                          t,
                                          teacherId,
                                          activeSlots,
                                          setActiveSlots,
                                          showCalendar = false,
                                          paddingOutline,
                                          goToConnectRequestPage = false,
                                          roleTeacher = false
                                        }) => {

  const [activeKey, setActiveKey] = useState("1")
  const [daysData, setDaysData] = useState<TWeekDay[]>([]);
  const [view, setView] = useState<EViews>(EViews.WEEK);
  const [upcomingSlots, setUpcomingSlots] = useState<TUpcomingSlot[]>([]);
  const [daysDisplayRange, setDaysDisplayRange] = useState<TDaysDisplayRange>({rangeStart: 0, rangeEnd: 7});
  const [selectedDate, setSelectedDate] = useState<Dayjs>(calendarDateRange[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeSlotsLimitModalOpen, setActiveSlotsLimitModalOpen] = useState<boolean>(false);

  const timeFrom = dayjs().utc().startOf("day").toISOString();
  const timeTo = dayjs().utc().add(28, "days").endOf("day").toISOString();

  const daysDataForDisplay = daysData.slice(daysDisplayRange.rangeStart, daysDisplayRange.rangeEnd);

  const options = [
    {value: EViews.DAY, label: capitalizeFirstLetter(t(`my-schedule.${EViews.DAY}`))},
    {value: EViews.WEEK, label: capitalizeFirstLetter(t(`my-schedule.${EViews.WEEK}`))}
  ]

  const items = [
    {
      key: "1",
      label: "All",
      children: <SlotsBoard
        goToConnectRequestPage={goToConnectRequestPage}
        setActiveSlotsLimitModalOpen={setActiveSlotsLimitModalOpen}
        onSwipeLeft={handleDateSwitchRight}
        onSwipeRight={handleDateSwitchLeft}
        teacherId={teacherId}
        view={view}
        daysDataForDisplay={daysDataForDisplay}
        activeSlots={activeSlots}
        setActiveSlots={setActiveSlots}
        selectedDate={selectedDate}
        roleTeacher={roleTeacher}
      />
    },
    // {
    //   key: "2",
    //   label: (
    //     <div style={{display: "flex", alignItems: "center"}}>
    //       <IndividualIconStyled style={{marginRight: "8px"}}/>
    //       Individual lesson
    //     </div>
    //   ),
    //   children: "BBB CHILDREN"
    // },
    // {
    //   key: "3",
    //   label: (
    //     <div style={{display: "flex", alignItems: "center"}}>
    //       <GroupIconStyled style={{marginRight: "8px"}}/>
    //       Group lesson
    //     </div>
    //   ),
    //   children: "CCC CHILDREN"
    // }
  ];

  const isDesktop = useMediaQuery({
    query: `(${device.desktop})`
  });

  const isMobile = useMediaQuery({
    query: `(${device.mobileMax})`,
  });

  const {language, antLocale} = useTranslate();

  useEffect(() => {
    if (!teacherId) return

    setIsLoading(true);

    getLessonSchedule(teacherId, timeFrom, timeTo)
      .then(({data}) => {
        setDaysData(generateDays(data.timeSlots, language));
        setUpcomingSlots(mapUpcomingSlots(data.nextAvailableTimeSlots, language));
      }).finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (view !== EViews.WEEK) return

    if (isMobile) {
      setDaysDisplayRange(prev => ({...prev, rangeEnd: prev.rangeStart + 3}));
    } else {
      setDaysDisplayRange(prev => ({...prev, rangeEnd: prev.rangeStart + 7}));
    }

  }, [isMobile]);

  function handleViewChange(value: unknown) {
    setDaysDisplayRange(prev => defineDaysDisplayRangeOnViewChange(prev, value as EViews, isMobile, showCalendar, daysData, selectedDate));
    setView(value as EViews);
  }

  function handleDateSwitchLeft() {
    setDaysDisplayRange(prev => defineDaysDisplayRangeOnDateSwitchLeft(prev, view, isMobile));
  }

  function handleDateSwitchRight() {
    setDaysDisplayRange(prev => defineDaysDisplayRangeOnDateSwitchRight(prev, view, isMobile));
  }

  function handleCalendarDateChange(date: Dayjs) {
    if (view === EViews.WEEK) {
      if (isMobile) {
        setDaysDisplayRange(prev => defineDaysDisplayRangeOnDateSelectWithViewWeekForMobile(date, daysData, prev));
      } else {
        setDaysDisplayRange(prev => defineDaysDisplayRangeOnDateSelectWithViewWeek(date, daysData, prev));
      }
    } else {
      setDaysDisplayRange(prev => defineDaysDisplayRangeOnDateSelectWithViewDay(date, daysData, prev));
    }

    setSelectedDate(date)
  }

  return (
    <LessonPickerWrapperStyled>
      <ActiveSlotsLimitModalStyled open={activeSlotsLimitModalOpen} footer={null} closable={false}>
        <h1>{t("lessons.creation.active-slots-limit.title")}</h1>
        <p>{t("lessons.creation.active-slots-limit.description")}</p>
        <StyledButton onClick={() => setActiveSlotsLimitModalOpen(false)}>
          {t("lessons.creation.active-slots-limit.understood")}
        </StyledButton>
      </ActiveSlotsLimitModalStyled>
      {showCalendar && <div className="calendar-container">
          <ConfigProvider locale={antLocale}>
              <CalendarStyled
                  fullscreen={false}
                  value={selectedDate}
                  validRange={calendarDateRange}
                  onChange={handleCalendarDateChange}
                  headerRender={({value}) => {
                    const localizedMonth = value.locale(antLocale.locale).format("MMMM");
                    const year = value.format("YYYY");

                    return (
                      <div className="custom-calendar-header">
                        {localizedMonth} {year}
                      </div>
                    )
                  }}
              />
          </ConfigProvider>
      </div>}

      {!isDesktop && showCalendar && <StyledDivider/>}

      <LessonPickerStyled className={paddingOutline ? "padding-outline" : undefined}>
        <div className="header">
          <div className="header-controls">
            <SelectStyled className="view-select"
                          value={{value: view, label: capitalizeFirstLetter(t(`my-schedule.${view}`))}}
                          options={options} onChange={handleViewChange}/>
            <PrimaryDate daysDataForDisplay={daysDataForDisplay} language={language}/>
            {!isMobile && <div className="date-switch-buttons">
                <button disabled={daysDisplayRange.rangeStart === FIRST_DAY_INDEX} onClick={handleDateSwitchLeft}>
                    <LeftOutlined/>
                </button>
                <button disabled={daysDisplayRange.rangeEnd === LAST_DAY_INDEX} onClick={handleDateSwitchRight}>
                    <RightOutlined/>
                </button>
            </div>}
          </div>
          <UpcomingSlotsBanner t={t} teacherId={teacherId} setActiveSlotsLimitModalOpen={setActiveSlotsLimitModalOpen}
                               isMobile={isMobile}
                               roleTeacher={roleTeacher}
                               daysData={daysData} view={view} loading={isLoading} upcomingSlots={upcomingSlots}
                               activeSlots={activeSlots} setDaysDisplayRange={setDaysDisplayRange}
                               setActiveSlots={setActiveSlots} goToConnectRequestPage={goToConnectRequestPage}/>
        </div>
        <div className="body">
          <ScheduleTabsStyled onChange={setActiveKey} activeKey={activeKey} defaultActiveKey="1" items={items}
                              destroyInactiveTabPane/>
        </div>
      </LessonPickerStyled>
    </LessonPickerWrapperStyled>
  );
}

export function defineDaysDisplayRangeOnDateSelectWithViewDay(selectedDate: Dayjs, daysData: TWeekDay[], daysDisplayRange: TDaysDisplayRange) {

  const indexOfDayThatMatchesSelectedDate = daysData.findIndex((day) => dayjs(day.date).isSame(selectedDate.format("YYYY-MM-DD")));

  return {
    ...daysDisplayRange,
    rangeStart: indexOfDayThatMatchesSelectedDate,
    rangeEnd: indexOfDayThatMatchesSelectedDate + DAY_VIEW_RATE
  };
}

export function defineDaysDisplayRangeOnDateSelectWithViewWeek(selectedDate: Dayjs, daysData: TWeekDay[], daysDisplayRange: TDaysDisplayRange) {
  const indexOfDayThatMatchesSelectedDate = daysData.findIndex((day) => dayjs(day.date).isSame(selectedDate.format("YYYY-MM-DD")));

  if (indexOfDayThatMatchesSelectedDate < daysDisplayRange.rangeStart) {
    return {
      ...daysDisplayRange,
      rangeStart: indexOfDayThatMatchesSelectedDate,
      rangeEnd: indexOfDayThatMatchesSelectedDate + WEEK_VIEW_RATE
    };
  }

  if (indexOfDayThatMatchesSelectedDate >= daysDisplayRange.rangeEnd) {
    if (indexOfDayThatMatchesSelectedDate + WEEK_VIEW_RATE > LAST_DAY_INDEX) return {...WEEK_VIEW_FINAL_SEGMENT_RANGE};

    return {
      ...daysDisplayRange,
      rangeStart: indexOfDayThatMatchesSelectedDate,
      rangeEnd: indexOfDayThatMatchesSelectedDate + WEEK_VIEW_RATE
    };
  }

  return daysDisplayRange;
}

export function defineDaysDisplayRangeOnDateSelectWithViewWeekForMobile(selectedDate: Dayjs, daysData: TWeekDay[], daysDisplayRange: TDaysDisplayRange) {
  const indexOfDayThatMatchesSelectedDate = daysData.findIndex((day) => dayjs(day.date).isSame(selectedDate.format("YYYY-MM-DD")));

  if (indexOfDayThatMatchesSelectedDate < daysDisplayRange.rangeStart) {
    return {
      ...daysDisplayRange,
      rangeStart: indexOfDayThatMatchesSelectedDate,
      rangeEnd: indexOfDayThatMatchesSelectedDate + WEEK_VIEW_RATE_REDUCED
    };
  }

  if (indexOfDayThatMatchesSelectedDate >= daysDisplayRange.rangeEnd) {
    if (indexOfDayThatMatchesSelectedDate + WEEK_VIEW_RATE_REDUCED > LAST_DAY_INDEX) return {...WEEK_VIEW_FINAL_SEGMENT_RANGE_REDUCED};
    return {
      ...daysDisplayRange,
      rangeStart: indexOfDayThatMatchesSelectedDate,
      rangeEnd: indexOfDayThatMatchesSelectedDate + WEEK_VIEW_RATE_REDUCED
    };
  }

  return daysDisplayRange;
}

function defineDaysDisplayRangeOnViewChange(range: TDaysDisplayRange, view: EViews, mobile: boolean, showCalendar: boolean, daysData: TWeekDay[], selectedDate: Dayjs): TDaysDisplayRange {
  if (view === EViews.DAY) {
    if (showCalendar) {
      const indexOfDayThatMatchesSelectedDate = daysData.findIndex((day) => dayjs(day.date).isSame(selectedDate.format("YYYY-MM-DD")));

      return {
        rangeStart: indexOfDayThatMatchesSelectedDate,
        rangeEnd: indexOfDayThatMatchesSelectedDate + DAY_VIEW_RATE
      };
    }
    return {...range, rangeEnd: range.rangeStart + DAY_VIEW_RATE}
  } else {

    if (mobile) {
      if (range.rangeEnd + WEEK_VIEW_RATE_REDUCED > LAST_DAY_INDEX) return {...WEEK_VIEW_FINAL_SEGMENT_RANGE_REDUCED};
      return {...range, rangeEnd: range.rangeStart + WEEK_VIEW_RATE_REDUCED};
    } else {
      if (range.rangeEnd + WEEK_VIEW_RATE > LAST_DAY_INDEX) return {...WEEK_VIEW_FINAL_SEGMENT_RANGE};

      return {...range, rangeEnd: range.rangeStart + WEEK_VIEW_RATE};
    }
  }
}

function defineDaysDisplayRangeOnDateSwitchRight(range: TDaysDisplayRange, view: EViews, mobile: boolean): TDaysDisplayRange {

  if (view === EViews.DAY) {

    if (range.rangeEnd + DAY_VIEW_RATE > LAST_DAY_INDEX) return {...DAY_VIEW_FINAL_SEGMENT_RANGE};
    return {rangeStart: range.rangeStart + DAY_VIEW_RATE, rangeEnd: range.rangeEnd + DAY_VIEW_RATE}
  }

  if (mobile) {

    if (range.rangeEnd + WEEK_VIEW_RATE_REDUCED > LAST_DAY_INDEX) return {...WEEK_VIEW_FINAL_SEGMENT_RANGE_REDUCED};
    return {rangeStart: range.rangeStart + WEEK_VIEW_RATE_REDUCED, rangeEnd: range.rangeEnd + WEEK_VIEW_RATE_REDUCED};
  } else {

    if (range.rangeEnd + WEEK_VIEW_RATE > LAST_DAY_INDEX) return {...WEEK_VIEW_FINAL_SEGMENT_RANGE};

    return {rangeStart: range.rangeStart + WEEK_VIEW_RATE, rangeEnd: range.rangeEnd + WEEK_VIEW_RATE};
  }
}

function defineDaysDisplayRangeOnDateSwitchLeft(range: TDaysDisplayRange, view: EViews, mobile: boolean): TDaysDisplayRange {

  if (view === EViews.DAY) {

    if (range.rangeStart - DAY_VIEW_RATE < FIRST_DAY_INDEX) return {...DAY_VIEW_INITIAL_SEGMENT_RANGE};
    return {rangeStart: range.rangeStart - DAY_VIEW_RATE, rangeEnd: range.rangeEnd - DAY_VIEW_RATE};
  }

  if (mobile) {

    if (range.rangeStart - WEEK_VIEW_RATE_REDUCED < FIRST_DAY_INDEX) return {...WEEK_VIEW_INITIAL_SEGMENT_RANGE_REDUCED};
    return {rangeStart: range.rangeStart - WEEK_VIEW_RATE_REDUCED, rangeEnd: range.rangeEnd - WEEK_VIEW_RATE_REDUCED};
  } else {

    if (range.rangeStart - WEEK_VIEW_RATE < FIRST_DAY_INDEX) return {...WEEK_VIEW_INITIAL_SEGMENT_RANGE};

    return {rangeStart: range.rangeStart - WEEK_VIEW_RATE, rangeEnd: range.rangeEnd - WEEK_VIEW_RATE};
  }
}

function generateDays(slots: TScheduleSlot[], language: Translations): TWeekDay[] {

  const weekDays: TWeekDay[] = [];
  const startDayOfWeek = dayjs().locale(language);

  for (let i = 0; i < 28; i++) {
    const currentDay = startDayOfWeek.add(i, "day");

    weekDays.push({
      dayName: currentDay.format("ddd"),
      dayNumber: currentDay.format("D"),
      date: currentDay.format("YYYY-MM-DD"),
      slots: []
    });
  }

  slots.forEach((slot: any) => {
    const slotDate = getHourWithTzOffsetDayjs(slot.startDate, timeFormat).format("YYYY-MM-DD");

    const day = weekDays.find((weekDay) => weekDay.date === slotDate);

    if (day) {
      day.slots.push({
        ...slot,
        startDateDisplayFormat: getHourWithTzOffsetDayjs(slot.startDate, timeFormat).format("HH:mm")
      });
    }
  })

  return weekDays;
}

function mapUpcomingSlots(upcomingSlots: string[], language: Translations): TUpcomingSlot[] {
  return upcomingSlots.map((slot) => (
    {
      startDate: slot,
      startDateDisplayFormat: getHourWithTzOffsetDayjs(slot, timeFormat)
        .locale(language)
        .format("D ddd, HH:mm")
    }
  ))
}

