import React, {
    createContext,
    FC,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
  } from 'react';
  
  interface SiderContextValue {
    isSiderOpen: boolean;
    setIsSiderOpen: Dispatch<SetStateAction<boolean>>;
  }
  
  interface Props {
    children: React.ReactNode;
  }
  
  const SiderContext = createContext<SiderContextValue>({
    isSiderOpen: true,
    setIsSiderOpen: () => {},
  });
  
  export const useSiderContext = (): SiderContextValue => {
    return useContext(SiderContext);
  };
  
  const SiderContextProvider: FC<Props> = ({ children }) => {
    const [isSiderOpen, setIsSiderOpen] = useState<boolean>(true);
  
    return (
      <SiderContext.Provider value={{ isSiderOpen, setIsSiderOpen }}>
        {children}
      </SiderContext.Provider>
    );
  };
  
  export default SiderContextProvider;
  